import React, { useState, useEffect, useCallback } from "react";

import styled from "styled-components";
import { Box, Stack, Switch, Typography } from "@mui/material";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import StepBar from "components/form/StepBar";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import NewTextEditor from "components/form/NewTextEditor";

import { useDispatch, useSelector } from "react-redux";
import { selectLibraryCoe } from "redux/selectors";
import { getSubjectList, createExamCoE } from "redux/actions/library-coe";
import Radio from "components/form/Radio";

const PopupContainer = styled.div`
  width: 1200px;
`;

function PopupCreateExamCoE({ open, onClose, maxWidth = "xl" }) {
  // external hook
  const dispatch = useDispatch();

  // initiate data
  const taxonomyList = [
    {
      text: "Remember",
      value: "Remember",
    },
    {
      text: "Understand",
      value: "Understand",
    },
    {
      text: "Apply",
      value: "Apply",
    },
    {
      text: "Analyze",
      value: "Analyze",
    },
    {
      text: "Evaluate",
      value: "Evaluate",
    },
    {
      text: "Create",
      value: "Create",
    },
  ];
  const difficultyLevelList = [
    { value: "easy", text: "ง่าย" },
    { value: "medium", text: "กลาง" },
    { value: "hard", text: "ยาก" },
  ];
  const initialGroupSubjects = [
    {
      text: "วิชาพื้นฐาน",
      value: "basic",
    },
    {
      text: "วิชาเฉพาะ",
      value: "specific",
    },
  ];
  const questionTypeList = [{ value: "multiple", text: "multiple" }];
  const { subjectList } = useSelector(selectLibraryCoe);
  const [committees, setCommittees] = useState([]);

  const fetchSubjectList = useCallback(() => {
    dispatch(getSubjectList());
  }, [dispatch]);
  const initiateCommittees = () => {
    const newCommittees = [...Array(10)].map((_, index) => ({
      text: `สมัยที่ ${index + 1}`,
      value: `สมัยที่ ${index + 1}`,
    }));
    setCommittees(newCommittees);
  };

  useEffect(() => {
    fetchSubjectList();
    initiateCommittees();
  }, []);

  // form
  const intialForm = {
    subject_title: "",
    exam_section: "",
    creator: "",
    difficulty_level: "",
    amount_answer: "4",
    question: "",
    question_type: "multiple",
    taxonomy: "",
    committee: "",
    subject_group: "",
    is_random_answer: false,
    answer: "",
  };
  const [form, setForm] = useState(intialForm);
  const [choiceForms, setChoiceForms] = useState([]);
  const initialFirstError = {
    subject_title: false,
    exam_section: false,
    creator: false,
    taxonomy: false,
    committee: false,
    subject_group: false,
    difficulty_level: false,
  };
  const [errorsFirstForm, setErrorsFirstForm] = useState(initialFirstError);
  const initialErrorSecondForm = {
    question: false,
    answer: false,
  };
  const [errorsSecondForm, setErrorsSecondForm] = useState(
    initialErrorSecondForm
  );

  const isValidateFirstForm = () => {
    const tempErrors = {
      ...errorsFirstForm,
    };

    for (let key of Object.keys(tempErrors)) {
      if (
        key === "subject_title" ||
        key === "taxonomy" ||
        key === "subject_group" ||
        key === "difficulty_level"
      ) {
        tempErrors[key] = form[key] === "" ? "กรุณาเลือก" : false;
      } else {
        tempErrors[key] = form[key] === "" ? "กรุณากรอก" : false;
      }
    }

    setErrorsFirstForm({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === false);
  };
  const isValidateSecondForm = () => {
    const objectChoices = choiceForms.reduce((accumulator, currentObject) => {
      const transformedObject = Object.keys(currentObject).reduce(
        (acc, key) => {
          acc[key] =
            currentObject[key] === "" ? "" : JSON.stringify(currentObject[key]);
          return acc;
        },
        {}
      );

      return { ...accumulator, ...transformedObject };
    }, {});

    const tempErrors = {
      ...errorsSecondForm,
      ...objectChoices,
    };
    const tempForm = {
      answer: form.answer,
      question: form.question,
      ...objectChoices,
    };

    for (let key of Object.keys(tempErrors)) {
      if (key === "answer") {
        tempErrors[key] = tempForm[key] === "" ? "กรุณาเลือก" : false;
      } else {
        tempErrors[key] = tempForm[key] === "" ? "กรุณากรอก" : false;
      }
    }

    setErrorsSecondForm({ ...tempErrors });

    return Object.values(tempErrors).every((x) => x === false);
  };
  const onChangeForm = (event) => {
    const { name, value } = event.target;

    setForm((prev) => ({
      ...prev,
      [name]: name === "amount_answer" ? value.replace(/\D/g, "") : value,
    }));
  };
  const onChangeTextEditorForQuestion = (value) => {
    setForm((prev) => ({
      ...prev,
      question: value,
    }));
  };
  const onChangeTextEditorForChoice = (index, name, value) => {
    setChoiceForms((prev) =>
      prev.map((v, i) => (index === i ? { ...v, [name]: value } : v))
    );
  };
  const setAnswer = (event) => {
    const { value } = event.target;
    setForm((prev) => ({
      ...prev,
      answer: value,
    }));
  };
  const toggleIsRandom = (event) => {
    const { checked } = event.target;

    setForm((prev) => ({
      ...prev,
      is_random_answer: checked,
    }));
  };
  const onSubmitCreateExam = () => {
    const isValidate = isValidateSecondForm();

    if (isValidate) {
      const objectChoices = choiceForms.reduce((accumulator, currentObject) => {
        const transformedObject = Object.keys(currentObject).reduce(
          (acc, key) => {
            acc[key] = JSON.stringify(currentObject[key]);
            return acc;
          },
          {}
        );
        return { ...accumulator, ...transformedObject };
      }, {});
      const request = {
        ...form,
        answer: Number(form.answer),
        question: JSON.stringify(form.question),
        ...objectChoices,
      };
      dispatch(createExamCoE(request));
      onClose();
    }
  };

  // subject list
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const newSubjects = subjectList.map((v) => ({
      text: v.subject_title,
      value: v.subject_title,
    }));
    setSubjects(newSubjects);
  }, [subjectList]);

  // step
  const steps = ["กรอกข้อมูลข้อสอบ", "สร้างโจทย์"];
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    const isValidate = isValidateFirstForm();

    if (isValidate) {
      const choiceForms = Array.from(
        { length: Number(form.amount_answer) },
        (_, index) => ({
          [`c${index + 1}`]: "",
        })
      );

      setChoiceForms(choiceForms);
      setCurrentStep(1);
    }
  };
  const backStep = () => {
    setCurrentStep(0);
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <PopupContainer>
        <Typography
          variant="h4"
          sx={{
            mb: 2,
          }}
        >
          สร้างข้อสอบใหม่
        </Typography>

        <Box mb={2}>
          <StepBar steps={steps} activeStep={currentStep} />
        </Box>

        {currentStep === 0 ? (
          <>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              รายละเอียดเกี่ยวกับข้อสอบ
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Select
                label="วิชา"
                items={subjects}
                value={form.subject_title}
                defaultValue="วิชา"
                name="subject_title"
                onChange={onChangeForm}
                errorText={errorsFirstForm.subject_title}
                isError={errorsFirstForm.subject_title}
              />
              <TextField
                name="exam_section"
                value={form.exam_section}
                onChange={onChangeForm}
                label="หัวข้อสอบ"
                error={errorsFirstForm.exam_section}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <TextField
                label="กรอกชื่อผู้ออกข้อสอบ"
                name="creator"
                value={form.creator}
                onChange={onChangeForm}
                error={errorsFirstForm.creator}
              />
              <Select
                label="ประเภทข้อสอบ"
                items={questionTypeList}
                value={form.question_type}
                defaultValue="ประเภทข้อสอบ"
                name="question_type"
                onChange={onChangeForm}
                disabled={true}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Select
                label="Taxonomy"
                items={taxonomyList}
                value={form.taxonomy}
                defaultValue="Taxonomy"
                name="taxonomy"
                onChange={onChangeForm}
                errorText={errorsFirstForm.taxonomy}
                isError={errorsFirstForm.taxonomy}
              />
              <Select
                label="คณะกรรมการสมัยที่ออกข้อสอบ"
                items={committees}
                value={form.committee}
                defaultValue="คณะกรรมการสมัยที่ออกข้อสอบ"
                name="committee"
                onChange={onChangeForm}
                errorText={errorsFirstForm.committee}
                isError={errorsFirstForm.committee}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Box sx={{ width: "49%" }}>
                <Select
                  label="หมวดวิชา"
                  items={initialGroupSubjects}
                  value={form.subject_group}
                  defaultValue="หมวดวิชา"
                  name="subject_group"
                  onChange={onChangeForm}
                  errorText={errorsFirstForm.subject_group}
                  isError={errorsFirstForm.subject_group}
                />
              </Box>
            </Stack>

            <Typography
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              ข้อมูลเกี่ยวกับโจทย์
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mb: 4,
              }}
            >
              <Select
                label="ระดับความยาก"
                items={difficultyLevelList}
                name="difficulty_level"
                value={form.difficulty_level}
                defaultValue="ระดับความยาก"
                onChange={onChangeForm}
                errorText={errorsFirstForm.difficulty_level}
                isError={errorsFirstForm.difficulty_level}
              />
              <TextField
                name="amount_answer"
                value={form.amount_answer}
                onChange={onChangeForm}
                label="จำนวนคำตอบ"
                disabled={true}
              />
            </Stack>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              โจทย์
            </Typography>

            <Box mb={2}>
              <NewTextEditor
                error={errorsSecondForm.question}
                setValue={onChangeTextEditorForQuestion}
              />
            </Box>

            {choiceForms.map((v, i) => (
              <Box mb={2}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="h5">คำตอบที่ {i + 1}</Typography>
                  <div>
                    <Radio
                      id={i + 1}
                      name="answer"
                      value={i + 1}
                      label="เลือกเป็นคำตอบที่ถูกต้อง"
                      checked={form.answer == i + 1}
                      onChange={setAnswer}
                      error={errorsSecondForm.answer}
                    />
                  </div>
                </Stack>

                <NewTextEditor
                  error={errorsSecondForm[Object.keys(v)[0]]}
                  setValue={(value) =>
                    onChangeTextEditorForChoice(i, Object.keys(v)[0], value)
                  }
                />
              </Box>
            ))}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography variant="body1">
                หมายเหตุ : การแก้ไขข้อสอบ จะต้องอนุมัติข้อสอบใหม่อีกครั้ง
              </Typography>
              <Box className="text-right">
                <span> ต้องการ Random คำตอบหรือไม่ </span>

                <Switch
                  checked={form.is_random_answer}
                  onChange={toggleIsRandom}
                />
              </Box>
            </Stack>
          </>
        )}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {currentStep === 0 ? (
            <Button onClick={onClose} outlined>
              ยกเลิก
            </Button>
          ) : (
            <Button onClick={backStep} outlined>
              กลับ
            </Button>
          )}

          {currentStep === 0 ? (
            <Button onClick={nextStep}>
              ถัดไป
              <ArrowForwardIcon />
            </Button>
          ) : (
            <Button onClick={onSubmitCreateExam}>บันทึก</Button>
          )}
        </Stack>
      </PopupContainer>
    </BaseModal>
  );
}

export default PopupCreateExamCoE;
