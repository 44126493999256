import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import { Stack } from "@mui/material";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import PopupAmountExamForTopicSubject from "containers/popup/PopupAmountExamForTopicSubject";
import PopupCreateSubject from "containers/popup/PopupCreateSubject";
import PopupEditSubject from "containers/popup/PopupEditSubject";
import PopupDeleteSubject from "containers/popup/PopupDeleteSubject";

import { useDispatch, useSelector } from "react-redux";
import { selectLibraryCoe } from "redux/selectors";
import { getExamSubjects, getSubjectList } from "redux/actions/library-coe";

const Div = styled.div`
  h2 {
    margin: 0;
  }
  .mb-10-px {
    margin-bottom: 10px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .add-buton {
    background-color: #01b982;
  }

  .field-input {
    width: 32%;
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;
const Divider = styled.div`
  height: 1px;
  background-color: lightgray;
`;

function TopicSubject() {
  // external hook
  const dispatch = useDispatch();

  // initiate data
  const initialGroupSubjects = [
    {
      text: "วิชาพื้นฐาน",
      value: "basic",
    },
    {
      text: "วิชาเฉพาะ",
      value: "specific",
    },
  ];
  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };
  const columns = [
    {
      field: "subject_no",
      headerName: "รหัสวิชา",
      renderCell: IsNullValue,
      width: 100,
    },
    {
      field: "subject_title",
      headerName: "ชื่อวิชา",
      renderCell: IsNullValue,
      width: 300,
    },
    {
      field: "subject_group",
      headerName: "หมวดวิชา",
      renderCell: ({ value }) => {
        return value === "basic"
          ? "วิชาพื้นฐาน"
          : value === "specific"
          ? "วิชาเฉพาะ"
          : "-";
      },
      width: 200,
    },
    {
      field: "department",
      headerName: "สาขา",
      renderCell: IsNullValue,
      width: 250,
    },
    {
      field: "topic_count",
      headerName: "จำนวนหัวข้อข้อสอบ",
      width: 250,
    },
    {
      field: "question_count",
      headerName: "จำนวนข้อสอบ",
      renderCell: IsNullValue,
      width: 250,
    },
  ];
  // store
  const { examSubjectList, subjectList, total, limit } =
    useSelector(selectLibraryCoe);
  const [examSubjects, setExamSubjects] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const fetchExamSubjects = useCallback(
    (filter, skip, limit) => {
      dispatch(getExamSubjects(filter, skip, limit));
    },
    [dispatch]
  );
  const fetchSubjectList = useCallback(() => {
    dispatch(getSubjectList());
  }, [dispatch]);
  const initiateDepartmentList = () => {
    const { menu_list } = JSON.parse(localStorage.getItem("user"));
    const examCoePermission = menu_list.find((v) => v.key === "exam_coe");

    if (examCoePermission) {
      const { other_list } = examCoePermission;
      const newDepartmentList = other_list.map((v) => ({
        text: v.name,
        value: v.name,
      }));

      setDepartmentList(newDepartmentList);
    }
  };

  useEffect(() => {
    fetchExamSubjects();
    fetchSubjectList();
    initiateDepartmentList();
  }, []);

  useEffect(() => {
    const newSubjectList = examSubjectList.map((v, i) => ({
      id: i,
      ...v,
    }));
    setExamSubjects(newSubjectList);
  }, [examSubjectList]);

  // subject list
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    const newSubjects = subjectList.map((v) => ({
      text: v.subject_title,
      value: v.subject_title,
    }));
    setSubjects(newSubjects);
  }, [subjectList]);

  // subject info data
  const [subjectInfo, setSubjectInfo] = useState();

  // filter
  const initFilter = {
    subject_group: "",
    department: "",
    subject_title: "",
  };
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const handleFilterChange = (key, value) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleSearch = () => {
    setPage(0);
    fetchExamSubjects(filter, 0, limit);
  };
  const handleClearFilter = () => {
    setPage(0);
    setFilter(initFilter);
    fetchExamSubjects(initFilter, 0, limit);
  };
  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    const skipFilter = nextPage * limit;
    fetchExamSubjects(filter, skipFilter, limit);
  };
  const handleDeleteSubject = (row) => {
    setSubjectInfo(row);
    openDeleteModal();
  };
  const handleEditSubject = (row) => {
    setSubjectInfo(row);
    openEditModal();
  };
  const viewAmountSubject = ({ row }) => {
    setSubjectInfo(row);
    openAmountExamModal();
  };

  // amount exam modal
  const [amountExamModal, setAmountExamModal] = useState(false);
  const openAmountExamModal = () => {
    setAmountExamModal(true);
  };
  const closeAmountExamModal = () => {
    setAmountExamModal(false);
  };

  // create modal
  const [createModal, setCreateModal] = useState(false);
  const openCreateModal = () => {
    setCreateModal(true);
  };
  const closeCreateModal = () => {
    setCreateModal(false);
  };
  const onSubmitCreate = () => {
    closeCreateModal();
  };

  // edit modal
  const [editModal, setEditModal] = useState(false);
  const openEditModal = () => {
    setEditModal(true);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };
  const onSubmitEdit = () => {
    closeEditModal();
  };

  // delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const onSubmitDelete = () => {
    closeDeleteModal();
  };

  return (
    <SidebarLayout title="คลังข้อสอบภาคี">
      <Div>
        <SubMenu
          menu={[
            { path: "/library-coe/examination/all", text: "ข้อสอบ" },
            // { path: "/library-coe/topic-subject/all", text: "รายชื่อวิชา" },
            // { path: "/library-coe/topic-exam/all", text: "หัวข้อข้อสอบ" },
          ]}
        />

        <div className="content-wrapper">
          <Stack
            className="mb-16-px"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2>รายชื่อวิชา ภาคีวิศวกร</h2>
            <Button className="add-buton" onClick={openCreateModal}>
              เพิ่ม รายชื่อวิชา
            </Button>
          </Stack>

          <Divider className="mb-16-px" />

          <Stack
            className="mb-10-px"
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Select
              label="หมวดวิชา"
              items={initialGroupSubjects}
              value={filter.subject_group}
              defaultValue="หมวดวิชา"
              onChange={(e) =>
                handleFilterChange("subject_group", e.target.value)
              }
            />
            <Select
              label="สาขา"
              items={departmentList}
              value={filter.department}
              defaultValue="สาขา"
              onChange={(e) => handleFilterChange("department", e.target.value)}
            />
            <Select
              label="วิชา"
              items={subjects}
              value={filter.subject_title}
              defaultValue="วิชา"
              onChange={(e) =>
                handleFilterChange("subject_title", e.target.value)
              }
            />
          </Stack>

          <Stack
            className="mb-16-px"
            direction="row"
            alignItems="flex-end"
            spacing={1}
          >
            <Button onClick={handleSearch}>Search</Button>
            <Button onClick={handleClearFilter} outlined>
              Clear
            </Button>
          </Stack>

          <div className="table-wrapper">
            <div className="mb-16-px">จำนวนวิชา {total} วิชา</div>
            <Table
              columns={columns}
              rows={examSubjects}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              onDeleteRow={handleDeleteSubject}
              onEditRow={handleEditSubject}
            />
          </div>
        </div>

        {/* Modal */}
        <PopupAmountExamForTopicSubject
          open={amountExamModal}
          onClose={closeAmountExamModal}
          content={subjectInfo}
        />
        <PopupCreateSubject
          open={createModal}
          onClose={closeCreateModal}
          onSubmit={onSubmitCreate}
        />
        <PopupEditSubject
          open={editModal}
          onClose={closeEditModal}
          onSubmit={onSubmitEdit}
          content={subjectInfo}
        />
        <PopupDeleteSubject
          open={deleteModal}
          onClose={closeDeleteModal}
          onSubmit={onSubmitDelete}
          content={subjectInfo}
        />
      </Div>
    </SidebarLayout>
  );
}

export default TopicSubject;
