import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";
import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Date from "components/form/Date";

import { selectLibraryCoe } from "redux/selectors";
import { editExamCoe } from "redux/actions/library-coe";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .wrapper-item {
    margin-bottom: 20px;
  }
`;

const PopupExamQuestionStatusUpdateCoE = ({
  open,
  onClose,
  maxWidth = "xl",
  id,
}) => {
  // external
  const dispatch = useDispatch();

  // initial dropdown
  const initialStatusAsjust = [
    {
      text: "ปรับแล้ว",
      value: "adjust",
    },
    {
      text: "ยังไม่ปรับ",
      value: "unadjust",
    },
  ];

  const [committees, setCommittees] = useState([]);
  useEffect(() => {
    const newCommittees = [...Array(10)].map((_, index) => ({
      text: `สมัยที่ ${index + 1}`,
      value: `สมัยที่ ${index + 1}`,
    }));
    setCommittees(newCommittees);
  }, []);
  // store
  const { examCoeDetail } = useSelector(selectLibraryCoe);
  // form
  const initialForm = {
    import_date: "",
    import_person: "",
    status_adjust: "",
    creator: "",
    committee: "",
    updated_by: "",
  };
  const [form, setForm] = useState(initialForm);

  const onChangeForm = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onChangeDate = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      import_date: examCoeDetail?.import_date ?? "",
      import_person: examCoeDetail?.import_person ?? "",
      status_adjust: examCoeDetail?.status_adjust ?? "",
      creator: examCoeDetail?.creator ?? "",
      committee: examCoeDetail?.committee ?? "",
    }));
  }, [examCoeDetail]);

  const submitQuestionStatusUpdate = () => {
    const { first_name, last_name } = JSON.parse(localStorage.getItem("user"));
    const request = {
      ...form,
      import_date: moment(form.import_date).format("YYYY-MM-DD"),
      updated_by: `${first_name} ${last_name}`,
    };

    dispatch(editExamCoe(id, request));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">แก้ไขสถานะข้อสอบ</div>
            <p>
              <strong>รหัสข้อสอบ {examCoeDetail?.question_code ?? "-"}</strong>
            </p>
            <div className="wrapper-item">
              {/* <Select
                label="ผู้นำเข้าข้อสอบ"
                items={examImporterList}
                value={form.import_person}
                defaultValue="ผู้นำเข้าข้อสอบ"
                className="field-createby"
                onChange={(e) => handleCreateByChange(e.target.value)}
              /> */}
              <TextField
                label="ผู้นำเข้าข้อสอบ"
                name="import_person"
                value={form.import_person}
                onChange={onChangeForm}
                className="field-createby"
                required
              />
            </div>
            <div className="wrapper-item">
              <Date
                label="วันที่นำเข้าข้อสอบ"
                value={form.import_date}
                disableFuture
                onChange={(value) => onChangeDate("import_date", value)}
              />
            </div>
            <div className="wrapper-item">
              <TextField
                label="ชื่อผู้ออกข้อสอบ"
                name="creator"
                value={form.creator}
                onChange={onChangeForm}
                className="field-createby"
                required
              />
            </div>
            <div className="wrapper-item">
              <Select
                label="คณะกรรมการสมัยที่ออกข้อสอบ"
                items={committees}
                value={form.committee}
                defaultValue="คณะกรรมการสมัยที่ออกข้อสอบ"
                className="field-createby"
                onChange={(e) => onChangeDropdown("committee", e.target.value)}
              />
            </div>
            <div className="wrapper-item">
              <Select
                label="สถานะการปรับปรุงข้อสอบ"
                items={initialStatusAsjust}
                value={form.status_adjust}
                defaultValue=" สถานะการปรับปรุงข้อสอบ"
                className="field-createby"
                onChange={(e) =>
                  onChangeDropdown("status_adjust", e.target.value)
                }
              />
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก{" "}
          </Button>
          <ButtonBlack onClick={submitQuestionStatusUpdate}>
            แก้ไขข้อมูล{" "}
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupExamQuestionStatusUpdateCoE;
