import React from "react";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

const Div = styled.div`
  .welcome-wrapper {
    margin-top: 29px;
    height: 870px;
    padding: 228px 120px;
    font-family: "kelson_sansbold";
    background: #fff;
    border-radius: 8px;

    .sub-title {
      font-size: 32px;
      color: #080808;
    }

    .title {
      font-size: 56px;
      color: #a81e21;
      margin-top: 36px;
    }
  }
`;

const LibaryCoEWelcome = () => {
  return (
    <SidebarLayout title="คลังข้อสอบภาคี">
      <Div>
        <SubMenu
          menu={[
            { path: "/library-coe/examination/all", text: "ข้อสอบ" },
            // { path: "/library-coe/topic-subject/all", text: "รายชื่อวิชา" },
            // { path: "/library-coe/topic-exam/all", text: "หัวข้อข้อสอบ" },
          ]}
        />
        <div className="welcome-wrapper">
          <div className="sub-title">Welcome to</div>
          <div className="title">ระบบคลังข้อสอบภาคี</div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default LibaryCoEWelcome;
