import React from "react";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import { Stack } from "@mui/material";
import TextField from "components/form/TextField";
import Select from "components/form/Select";

const Div = styled.div`
  .text-center {
    text-align: center;
  }

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }

  h2,
  p {
    margin: 0;
  }

  .button-outline {
    border: 1px solid #982436;
    background-color: #fff;
    color: #982436;
  }
`;

function PopupDeleteExam({ open, onClose, onSubmit, content }) {
  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <h2 className="text-center mb-20-px">ลบรายการนี้หรือไม่</h2>
        <Select
          className="mb-16-px"
          label="เลือกชื่อวิชา"
          // items={dataSubject}
          // value={subjectId}
          defaultValue="เลือกชื่อวิชา"
          // onChange={(e) => handleSubjectChange(e.target.value)}
          disabled={true}
        />
        <TextField
          className="mb-16-px"
          label="กรอกชื่อหัวข้อ"
          value={content?.topic_subject}
          // onChange={(e) => setTopicName(e.target.value)}
          disabled={true}
          required
        />

        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button className="button-outline" onClick={onClose}>
            ยกเลิก
          </Button>
          <Button onClick={onSubmit}>ยืนยัน</Button>
        </Stack>
      </Div>
    </BaseModal>
  );
}

export default PopupDeleteExam;
