import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Switch from "@mui/material/Switch";
import NewTextEditor from "components/form/NewTextEditor";
import { Box, Radio, Stack, Typography } from "@mui/material";

import { selectLibraryCoe } from "redux/selectors";
import { editExamCoe } from "redux/actions/library-coe";

const Div = styled.div`
  padding: 20px;
  width: 1200px;

  .stepbar-container {
    padding: 0 250px;
    margin-top: 10px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
        padding: 6px;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 550px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }

  .answer-container {
    display: flex;
    .answer-item {
    }
  }

  .invilid {
    color: red;
    font-size: 16px;
    margin-top: 10px;
  }
`;

const PopupEditExamQuestionChoiceCoE = ({
  open,
  onClose,
  maxWidth = "xl",
  id,
}) => {
  // external hook
  const dispatch = useDispatch();

  // initial data
  const intialForm = {
    question: "",
    is_random_answer: false,
    answer: "",
  };
  const { examCoeDetail } = useSelector(selectLibraryCoe);
  const [form, setForm] = useState(intialForm);
  const [question, setQuestion] = useState();
  const [initialChoiceForms, setInitialChoiceForms] = useState([]);
  const [choiceForms, setChoiceForms] = useState([]);

  const onChangeTextEditorForQuestion = (value) => {
    setForm((prev) => ({
      ...prev,
      question: value,
    }));
  };

  const onChangeTextEditorForChoice = (index, name, value) => {
    setChoiceForms((prev) =>
      prev.map((v, i) => (index === i ? { ...v, [name]: value } : v))
    );
  };
  const setAnswer = (event) => {
    const { value } = event.target;
    setForm((prev) => ({
      ...prev,
      answer: value,
    }));
  };
  const toggleIsRandom = (event) => {
    const { checked } = event.target;

    setForm((prev) => ({
      ...prev,
      is_random_answer: checked,
    }));
  };
  const onSubmitEditExam = () => {
    const objectChoices = choiceForms.reduce((accumulator, currentObject) => {
      const transformedObject = Object.keys(currentObject).reduce(
        (acc, key) => {
          acc[key] =
            typeof currentObject[key] === "object"
              ? JSON.stringify(currentObject[key]).replace(/\n/g, "\\n")
              : currentObject[key];
          return acc;
        },
        {}
      );

      return { ...accumulator, ...transformedObject };
    }, {});

    const request = {
      ...form,
      ...objectChoices,
      answer: Number(form.answer),
      question:
        typeof form.question === "object"
          ? JSON.stringify(form.question)
          : form.question,
    };

    dispatch(editExamCoe(id, request));
    onClose();
  };

  useEffect(() => {
    const initiateForm = () => {
      setForm((prev) => ({
        ...prev,
        question: examCoeDetail.question,
        is_random_answer: examCoeDetail.is_random_answer,
        answer: examCoeDetail.answer,
      }));
    };
    const initiateQuestion = () => {
      const deltaObject = examCoeDetail.question;
      setQuestion(deltaObject);
    };
    const initiateChoicesForm = () => {
      const pattern = /^c\d+$/;
      const filteredData = Object.keys(examCoeDetail)
        .filter((key) => pattern.test(key))
        .map((key) => {
          return { [key]: examCoeDetail[key] };
        });

      setChoiceForms(filteredData);
      setInitialChoiceForms(filteredData);
    };

    initiateForm();
    initiateChoicesForm();
    initiateQuestion();
  }, [examCoeDetail]);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Typography
          variant="h4"
          sx={{
            mb: 2,
          }}
        >
          แก้ไขข้อสอบ
        </Typography>

        <Typography
          variant="h5"
          sx={{
            mb: 2,
          }}
        >
          โจทย์
        </Typography>

        {question && (
          <Box mb={2}>
            <NewTextEditor
              value={question}
              setValue={onChangeTextEditorForQuestion}
            />
          </Box>
        )}

        {choiceForms.map((v, i) => (
          <Box mb={2}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">คำตอบที่ {i + 1}</Typography>
              <div>
                <Radio
                  id={i + 1}
                  name="answer"
                  value={i + 1}
                  label="เลือกเป็นคำตอบที่ถูกต้อง"
                  checked={form.answer == i + 1}
                  onChange={setAnswer}
                />
              </div>
            </Stack>

            <NewTextEditor
              value={initialChoiceForms[i][Object.keys(v)[0]]}
              setValue={(value) =>
                onChangeTextEditorForChoice(i, Object.keys(v)[0], value)
              }
            />
          </Box>
        ))}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography variant="body1">
            หมายเหตุ : การแก้ไขข้อสอบ จะต้องอนุมัติข้อสอบใหม่อีกครั้ง
          </Typography>
          <Box className="text-right">
            <span> ต้องการ Random คำตอบหรือไม่ </span>

            <Switch checked={form.is_random_answer} onChange={toggleIsRandom} />
          </Box>
        </Stack>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            ยกเลิก{" "}
          </Button>
          <ButtonBlack onClick={onSubmitEditExam}>บันทึก</ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupEditExamQuestionChoiceCoE;
