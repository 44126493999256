import moment from "moment";
import _ from "lodash";

const decimal = (number = 0, isFloat = true) => {
  const digit = isFloat ? 2 : 0;
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  }).format(number);
};

const currency = (number = 0, symbol = "฿", isFloat) =>
  `${symbol} ${decimal(number, isFloat)}`;

export const TYPE = {
  DATE: "Date",
  DATE2: "Date2",
  TIME: "Time",
  DATETIME: "DateTime",
  NUMBER: "Number",
  DECIMAL: "Decimal",
  CURRENCY: "Currency",
};

const parseDateFromDDMMYYYY = (dateStr) => {
  if (!dateStr) return null;
  const parts = dateStr.split(/[-\/ :]/);
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  // For DATETIME type, also parse the time
  const hours = parts.length > 3 ? parseInt(parts[3], 10) : 0;
  const minutes = parts.length > 4 ? parseInt(parts[4], 10) : 0;
  const seconds = parts.length > 5 ? parseInt(parts[5], 10) : 0;

  return new Date(year, month, day, hours, minutes, seconds);
};

export default (type, value) => {
  const { DATE, DATE2, TIME, DATETIME, NUMBER, DECIMAL, CURRENCY } = TYPE;
  switch (type) {
    case DATE:
      return _.isNil(value)
        ? ""
        : moment(parseDateFromDDMMYYYY(value)).format("DD/MM/YYYY");
    case DATE2:
      return _.isNil(value)
        ? ""
        : moment(parseDateFromDDMMYYYY(value)).format("DD MMM YYYY");
    case TIME:
      return _.isNil(value)
        ? ""
        : moment(parseDateFromDDMMYYYY(value)).format("HH:mm:ss");
    case DATETIME:
      return _.isNil(value)
        ? ""
        : moment(parseDateFromDDMMYYYY(value)).format("DD/MM/YYYY HH:mm");
    case NUMBER:
      return _.isNil(value) ? "" : decimal(value, false);
    case DECIMAL:
      return _.isNil(value) ? "" : decimal(value);
    case CURRENCY:
      return _.isNil(value) ? "" : currency(value);
    default:
      return value;
  }
};

export const displayDate = (dateString) => {
  if (!dateString) return null;
  if (typeof dateString === "string") {
    const parts = dateString?.split(" ")[0].split("-");
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  } else {
    const formattedDate = moment(dateString).format("DD/MM/YYYY");
    return formattedDate;
  }
};
