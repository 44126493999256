import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { checkValue } from "utils/common";
import formatValue from "utils/formatValue";

import InlineSpaceText from "components/form/InlineSpaceText";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";
import ButtonBlack from "components/form/ButtonBlack";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";

import Paper from "components/surfaces/Paper";
import Divider from "components/surfaces/Divider";

import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

import ReactHtmlParser from "react-html-parser";

import {
  getExamQuestionById,
  getExamChoiceDetailById,
} from "redux/actions/library";
import { selectMasterSubject, selectMasterJob } from "../../redux/selectors";

import PopupEditExamQuestionChoice from "containers/popup/PopupEditExamQuestionChoice";
import PopupExamQuestionUpdate from "containers/popup/PopupExamQuestionUpdate";
import PopupExamQuestionStatusUpdate from "containers/popup/PopupExamQuestionStatusUpdate";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;

    :disabled {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
  }

  .question-img {
    max-width: 350px;
    padding: 10px 0;
    img {
      width: 100%;
    }
  }
  .question-item {
    display: flex;
    margin: 15px;
    .question-title {
      width: 200px;
      margin-left: 10px;
    }
    .question-detail {
      width: 100%;
    }
  }
`;

const ExaminationDetail = () => {
  const { id } = useParams();
  const masterDataSubject = useSelector(selectMasterSubject);
  const masterDataJob = useSelector(selectMasterJob);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpenPopupExamQuestionUpdate, setIsOpenPopupExamQuestionUpdate] =
    useState(false);
  const [isOpenPopupEditQuestion, setIsOpenPopupEditQuestion] = useState(false);
  const [
    isOpenPopupExamQuestionStatusUpdate,
    setIsOpenPopupExamQuestionStatusUpdate,
  ] = useState(false);

  const { examQuestionDetail: info } = useSelector((state) => state.library);

  useEffect(() => {
    getDetailQuestionExam();
  }, []);

  const getDetailQuestionExam = useCallback(() => {
    dispatch(getExamQuestionById(id));
  }, [id]);

  const handleEditQuestion = (row) => {
    dispatch(getExamChoiceDetailById(id)).then(() =>
      setIsOpenPopupEditQuestion(true)
    );
  };

  const handleCloseEditQuestion = () => {
    dispatch(getExamQuestionById(id)).then(() =>
      setIsOpenPopupEditQuestion(false)
    );
  };

  const handleExamQuestionUpdate = () => {
    setIsOpenPopupExamQuestionUpdate(true);
  };

  const handleCloseExamQuestionUpdate = () => {
    dispatch(getExamQuestionById(id)).then(() =>
      setIsOpenPopupExamQuestionUpdate(false)
    );
  };

  const handleExamQuestionStatusUpdate = (row) => {
    setIsOpenPopupExamQuestionStatusUpdate(true);
  };

  const handleCloseExamQuestionStatusUpdate = () => {
    dispatch(getExamQuestionById(id)).then(() =>
      setIsOpenPopupExamQuestionStatusUpdate(false)
    );
  };

  const DisplaySubjectName = (subjectId) => {
    if (subjectId) {
      return masterDataSubject
        ?.filter((it) => it.value === +subjectId)
        .map((it) => it.text);
    }
  };

  const DisplayJobName = (jobId) => {
    if (jobId) {
      return masterDataJob
        ?.filter((it) => it.value === jobId)
        .map((it) => it.text);
    }
  };

  return (
    <SidebarLayout title="Examination">
      {Boolean(isOpenPopupEditQuestion) && (
        <PopupEditExamQuestionChoice
          open
          onClose={() => handleCloseEditQuestion()}
          id={id}
        />
      )}
      {Boolean(isOpenPopupExamQuestionUpdate) && (
        <PopupExamQuestionUpdate
          open
          onClose={() => handleCloseExamQuestionUpdate()}
          id={id}
          dataEdit={info}
        />
      )}
      {Boolean(isOpenPopupExamQuestionStatusUpdate) && (
        <PopupExamQuestionStatusUpdate
          open
          onClose={() => handleCloseExamQuestionStatusUpdate()}
          id={id}
          dataEdit={info}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} className="headbar-panel-wrapper">
              <BackIcon
                className="back-icon"
                onClick={() => history.goBack()}
              />
              <span className="title">{info?.code}</span>
            </Grid>

            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid
                    item
                    spacing={1}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item alignItems="center" container xs={7}>
                      ข้อมูลเกี่ยวกับข้อสอบ
                    </Grid>
                    <Grid item xs={5} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText className="fix-height" label="สาขา">
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="หมวดวิชา">
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="วิชา">
                    <span>
                      {checkValue(DisplaySubjectName(info?.subject_id))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="หัวข้อ ">
                    <span>{checkValue(info?.topic_name)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="ระดับความยาก">
                    {/* <span>{checkValue(info?.level)}</span> */}
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="Taxonomy">
                    {/* <span>{checkValue(info?.level)}</span> */}
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  {/* <InlineSpaceText className="fix-height" label="ตำแหน่งงาน">
                    <span>
                      {checkValue(DisplayJobName(info?.job_position_id))}
                    </span>
                  </InlineSpaceText> */}
                  <InlineSpaceText
                    className="fix-height"
                    label="ชื่อผู้ออกข้อสอบ"
                  >
                    <span>{checkValue(info?.exam_owner)}</span>
                  </InlineSpaceText>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} direction="column" item>
                  <Grid
                    item
                    xs={12}
                    spacing={1}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item xs={7}>
                      สถานะข้อสอบ
                    </Grid>
                    <Grid item xs={5} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionStatusUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText
                    label="ชื่อผู้นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(info?.exam_owner)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(formatValue("Date", info?.createdAt))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="ชื่อผู้อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(info?.approve_by)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="ชื่อผู้ออกข้อสอบ"
                    className="fix-height "
                  >
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="คณะกรรมการสมัยที่ออกข้อสอบ"
                    className="fix-height "
                  >
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="สถานะการปรับปรุงข้อสอบ"
                    className="fix-height "
                  >
                    <span>[new binding]</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(formatValue("Date", info?.approve_date))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText label="สถานะข้อสอบ" className="fix-height ">
                    <span>{checkValue(info?.status)}</span>
                  </InlineSpaceText>
                  <br />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid item spacing={1} xs={12} container alignItems="center">
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <h3 style={{ marginRight: "5px" }}>โจทย์: </h3>
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleEditQuestion()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid>
                    <Grid item xs={12} container>
                      <div>
                        <span id="section_question">
                          {ReactHtmlParser(
                            info?.exam_questions_choices?.[0]
                              ?.choice_description
                          )}
                        </span>
                        <br />
                        {info?.exam_questions_choices?.[0]?.choice_image?.[0]
                          ?.image_url !== "" && (
                          <img
                            className="question-img"
                            src={
                              info?.exam_questions_choices?.[0]
                                ?.choice_image?.[0]?.image_url
                            }
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <div className="question-container">
                    {/* {`${JSON.stringify(info?.exam_questions_choices)}`} */}
                    {info?.exam_questions_choices?.map((it, index) => {
                      // return `${JSON.stringify(it?.choice_description)}`;
                      return (
                        index !== 0 && (
                          <div className="question-item">
                            <div className="question-title">
                              คำตอบที่ {index}{" "}
                            </div>
                            <div className="question-detail">
                              <span>
                                {it?.choice_description !== "" &&
                                  ReactHtmlParser(it?.choice_description)}
                              </span>
                              <br />
                              {it?.choice_image?.[0]?.image_url !== "" && (
                                <img
                                  className="question-img"
                                  src={it?.choice_image?.[0]?.image_url}
                                />
                              )}
                            </div>
                          </div>
                        )
                      );
                    })}
                    <div className="question-item">
                      <div className="question-title">คำตอบที่ถูกต้อง</div>
                      <div className="question-detail">
                        <span>
                          {checkValue(
                            info?.exam_questions_choices
                              ?.filter((it) => it.is_answer)
                              .map((it) => it.choice_no)
                              .join(", ")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">Random คำตอบ</div>
                      <div className="question-detail">
                        <span>
                          {Boolean(info?.is_random_answer) ? "ใช่" : "ไม่ใช่"}
                        </span>
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">ชื่อผู้ออกข้อสอบ</div>
                      <div className="question-detail">
                        <span>{info?.exam_owner}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationDetail;
