import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";
import TextField from "components/form/TextField";
import Select from "components/form/Select";

import { selectLibraryCoe } from "../../redux/selectors";
import { editExamCoe, getSubjectList } from "redux/actions/library-coe";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .sc-jrQzAO.cFjkMa {
    width: 100%;
  }

  .wrapper-item {
    margin-bottom: 20px;
  }
`;

const PopupExamQuestionUpdateCoE = ({ open, onClose, maxWidth = "xl", id }) => {
  // external hook
  const dispatch = useDispatch();

  // initiate data
  const fetchSubjectList = useCallback(() => {
    dispatch(getSubjectList());
  }, [dispatch]);

  useEffect(() => {
    fetchSubjectList();
  }, []);

  // initial dropdown list
  const departments = [
    {
      text: "ไฟฟ้าสื่อสาร",
      value: "ไฟฟ้าสื่อสาร",
    },
    {
      text: "อุตสาหการ",
      value: "อุตสาหการ",
    },
    {
      text: "สิ่งแวดล้อม",
      value: "สิ่งแวดล้อม",
    },
    {
      text: "เคมี",
      value: "เคมี",
    },
    {
      text: "เหมืองแร่งานเหมืองแร่",
      value: "เหมืองแร่งานเหมืองแร่",
    },
    {
      text: "เหมืองแร่ งานโลหะการ",
      value: "เหมืองแร่ งานโลหะการ",
    },
    {
      text: "โยธา",
      value: "โยธา",
    },
    {
      text: "เครื่องกล",
      value: "เครื่องกล",
    },
    {
      text: "ไฟฟ้ากำลัง",
      value: "ไฟฟ้ากำลัง",
    },
    {
      text: "วิชาพื้นฐาน",
      value: "วิชาพื้นฐาน",
    },
  ];
  const initialGroupSubjects = [
    {
      text: "วิชาพื้นฐาน",
      value: "basic",
    },
    {
      text: "วิชาเฉพาะ",
      value: "specific",
    },
  ];
  const difficultyLevelList = [
    { text: "ง่าย", value: "easy" },
    { text: "กลาง", value: "medium" },
    { text: "ยาก", value: "hard" },
  ];
  const taxonomyList = [
    {
      text: "Remember",
      value: "remember",
    },
    {
      text: "Understand",
      value: "understand",
    },
    {
      text: "Apply",
      value: "apply",
    },
    {
      text: "Analyze",
      value: "analyze",
    },
    {
      text: "Evaluate",
      value: "evaluate",
    },
    {
      text: "Create",
      value: "create",
    },
  ];

  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    const { menu_list } = JSON.parse(localStorage.getItem("user"));
    const examCoePermission = menu_list.find((v) => v.key === "exam_coe");

    if (examCoePermission) {
      const { other_list } = examCoePermission;
      const newDepartmentList = other_list.map((v) => ({
        text: v.name,
        value: v.name,
      }));

      setDepartmentList(newDepartmentList);
    }
  }, []);

  // store
  const { examCoeDetail, subjectList } = useSelector(selectLibraryCoe);
  // form
  const initialForm = {
    subject_title: "",
    subject_group: "",
    exam_section: "",
    difficulty_level: "",
    department: "",
    taxonomy: "",
    updated_by: "",
  };
  const [form, setForm] = useState(initialForm);

  const onChangeForm = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      subject_title: examCoeDetail.subject_title ?? "",
      subject_group: examCoeDetail.subject_group ?? "",
      exam_section: examCoeDetail.exam_section ?? "",
      difficulty_level: examCoeDetail.difficulty_level ?? "",
      department: examCoeDetail.department ?? "",
      taxonomy: examCoeDetail.taxonomy ?? "",
    }));
  }, [examCoeDetail]);

  // subject list
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const newSubjects = subjectList.map((v) => ({
      text: v.subject_title,
      value: v.subject_title,
    }));
    setSubjects(newSubjects);
  }, [subjectList]);

  const submitQuestionUpdate = (event) => {
    event.preventDefault();
    const { first_name, last_name } = JSON.parse(localStorage.getItem("user"));
    const request = {
      ...form,
      updated_by: `${first_name} ${last_name}`,
    };

    dispatch(editExamCoe(id, request));
    onClose();
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <form onSubmit={submitQuestionUpdate}>
          <Grid container spacing={2}>
            <Grid item xs>
              <div className="title">แก้ไขข้อมูลเกี่ยวกับข้อสอบ</div>
              <p>
                <strong>รหัสข้อสอบ {examCoeDetail?.question_code}</strong>
              </p>
              <div className="wrapper-item">
                <Select
                  label="สาขา"
                  items={departmentList}
                  value={form.department}
                  defaultValue="สาขา"
                  className="field-subject"
                  onChange={(e) =>
                    onChangeDropdown("department", e.target.value)
                  }
                />
              </div>
              <div className="wrapper-item">
                <Select
                  label="หมวดวิชา"
                  items={initialGroupSubjects}
                  value={form.subject_group}
                  defaultValue="หมวดวิชา"
                  className="field-subject"
                  onChange={(e) =>
                    onChangeDropdown("subject_group", e.target.value)
                  }
                />
              </div>
              <div className="wrapper-item">
                <Select
                  label="วิชา"
                  items={subjects}
                  value={form.subject_title}
                  defaultValue="วิชา"
                  className="field-subject"
                  onChange={(e) =>
                    onChangeDropdown("subject_title", e.target.value)
                  }
                />
              </div>
              <div className="wrapper-item">
                <TextField
                  label="หัวข้อ"
                  name="exam_section"
                  value={form.exam_section}
                  onChange={onChangeForm}
                  className="field-topic"
                  required
                />
              </div>
              <div className="wrapper-item">
                <Select
                  label="ระดับความยาก"
                  items={difficultyLevelList}
                  value={form.difficulty_level}
                  onChange={(e) =>
                    onChangeDropdown("difficulty_level", e.target.value)
                  }
                  defaultValue="ระดับความยาก"
                  className="field-level"
                />
              </div>
              <div className="wrapper-item">
                <Select
                  label="Taxonomy"
                  items={taxonomyList}
                  value={form.taxonomy}
                  onChange={(e) => onChangeDropdown("taxonomy", e.target.value)}
                  defaultValue="Taxonomy"
                  className="field-level"
                />
              </div>
            </Grid>
          </Grid>
          <div className="button-wrapper">
            <Button onClick={onClose} outlined>
              ยกเลิก{" "}
            </Button>
            <ButtonBlack type="submit">แก้ไขข้อมูล </ButtonBlack>
          </div>
        </form>
      </Div>
    </BaseModal>
  );
};

export default PopupExamQuestionUpdateCoE;
