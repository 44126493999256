import {
  GET_EXAM_COE_LIST,
  GET_EXAM_COE_BY_ID,
  EDIT_EXAM_COE,
  DELETE_EXAM_COE,
  GET_SUBJECT_LIST,
  GET_EXAM_SUBJECTS,
  GET_EXAM_TOPICS,
  SAVE_FILTER_EXAM,
  SAVE_PAGE,
  SAVE_TAB,
  CREATE_EXAM_COE,
  UPLOAD_FILE_QUESTION_EXAM,
  GET_EXAM_TRAIN_COE,
  GET_EXAM_TRAIN_COE_BY_ID,
  EDIT_EXAM_TRAIN_COE,
  DELETE_EXAM_TRAIN_COE,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

export const getExamCoeList =
  (filter = {}, skip = 0, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      dispatch({ type: GET_EXAM_COE_LIST.REQUEST });

      const params = {
        $skip: skip,
        $limit: limit,
        status_adjust: "unadjust",
        isArchive: false,
      };
      if (filter?.code !== "") {
        params["question_code"] = filter.code;
      }
      if (filter?.question !== "") {
        params["question"] = filter.question;
      }
      if (filter?.examOwner !== "") {
        params["creator"] = filter.examOwner;
      }
      if (filter?.groupSubject !== "") {
        params["subject_group"] = filter.groupSubject;
      }
      if (filter?.subject !== "") {
        params["subject_title"] = filter.subject;
      }
      if (filter?.topicName !== "") {
        params["exam_section"] = filter.topicName;
      }
      if (filter?.level !== "") {
        params["difficulty_level"] = filter.level;
      }
      if (filter?.status !== "") {
        params["status"] = filter.status;
      }

      const user = JSON.parse(localStorage.getItem("user"));
      const permission = user?.menu_list;

      if (permission.length > 0) {
        const examCoePermission = permission.find((v) => v.key === "exam_coe");
        const departmentsFilter = examCoePermission.other_list
          .map((v) => v.name)
          .join(",");
        params["department"] = departmentsFilter;
      }

      const response = await getAPI({
        url: `/question-exams`,
        params,
      });

      return dispatch({
        type: GET_EXAM_COE_LIST.SUCCESS,
        data: response,
      });
    } catch (error) {
      return dispatch({
        type: GET_EXAM_COE_LIST.FAILURE,
        error,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

export const getAdjustExamCoeList =
  (filter = {}, skip = 0, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      dispatch({ type: GET_EXAM_COE_LIST.REQUEST });

      const params = {
        $skip: skip,
        $limit: limit,
        status_adjust: "adjust",
        isArchive: true,
      };
      if (filter?.code !== "") {
        params["question_code"] = filter.code;
      }
      if (filter?.question !== "") {
        params["question"] = filter.question;
      }
      if (filter?.examOwner !== "") {
        params["creator"] = filter.examOwner;
      }
      if (filter?.groupSubject !== "") {
        params["subject_group"] = filter.groupSubject;
      }
      if (filter?.subject !== "") {
        params["subject_title"] = filter.subject;
      }
      if (filter?.topicName !== "") {
        params["exam_section"] = filter.topicName;
      }
      if (filter?.level !== "") {
        params["difficulty_level"] = filter.level;
      }
      if (filter?.status !== "") {
        params["status"] = filter.status;
      }

      const user = JSON.parse(localStorage.getItem("user"));
      const permission = user?.menu_list;

      if (permission.length > 0) {
        const examCoePermission = permission.find((v) => v.key === "exam_coe");
        const departmentsFilter = examCoePermission.other_list
          .map((v) => v.name)
          .join(",");
        params["department"] = departmentsFilter;
      }

      const response = await getAPI({
        url: `/question-exams`,
        params,
      });

      return dispatch({
        type: GET_EXAM_COE_LIST.SUCCESS,
        data: response,
      });
    } catch (error) {
      return dispatch({
        type: GET_EXAM_COE_LIST.FAILURE,
        error,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

export const getExamCoeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_COE_BY_ID.REQUEST });

    const response = await getAPI({
      url: `/question-exams/${id}`,
    });

    return dispatch({
      type: GET_EXAM_COE_BY_ID.SUCCESS,
      data: response,
    });
  } catch (error) {
    return dispatch({
      type: GET_EXAM_COE_BY_ID.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
  }
};

export const editExamCoe = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: EDIT_EXAM_COE.REQUEST });

    await patchAPI({
      url: `/question-exams/${id}`,
      data,
    });

    return dispatch({
      type: EDIT_EXAM_COE.SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: EDIT_EXAM_COE.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
    dispatch(getExamCoeById(id));
  }
};

export const deleteExamCoe = (id, data, status_adjust) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: DELETE_EXAM_COE.REQUEST });

    await patchAPI({
      url: `/question-exams/delete-question/${id}`,
      data,
    });

    return dispatch({
      type: DELETE_EXAM_COE.SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_EXAM_COE.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());

    if (status_adjust == "adjust") dispatch(getAdjustExamCoeList());
    else dispatch(getExamCoeList());
  }
};

export const getSubjectList = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_SUBJECT_LIST.REQUEST });

    const params = {
      $skip: 0,
      $limit: 1000,
    };

    const user = JSON.parse(localStorage.getItem("user"));
    const permission = user?.menu_list;

    if (permission.length > 0) {
      const examCoePermission = permission.find((v) => v.key === "exam_coe");
      const departmentsFilter = examCoePermission.other_list
        .map((v) => v.name)
        .join(",");
      params["department"] = departmentsFilter;
    }

    const { data } = await getAPI({
      url: "/question-exams-subject-list?",
      params,
    });

    return dispatch({
      type: GET_SUBJECT_LIST.SUCCESS,
      data,
    });
  } catch (error) {
    return dispatch({
      type: GET_SUBJECT_LIST.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
  }
};

export const getExamSubjects =
  (
    filter = { subject_group: "", subject_title: "", department: "" },
    skip = 0,
    limit = 10
  ) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      dispatch({ type: GET_EXAM_SUBJECTS.REQUEST });

      const params = {
        $skip: skip,
        $limit: limit,
      };
      if (filter?.subject_group !== "") {
        params["subject_group"] = filter.subject_group;
      }
      if (filter?.subject_title !== "") {
        params["subject_title"] = filter.subject_title;
      }
      if (filter?.department !== "") {
        params["department"] = filter.department;
      } else {
        const user = JSON.parse(localStorage.getItem("user"));
        const permission = user?.menu_list;

        if (permission.length > 0) {
          const examCoePermission = permission.find(
            (v) => v.key === "exam_coe"
          );
          const departmentsFilter = examCoePermission.other_list
            .map((v) => v.name)
            .join(",");
          params["department"] = departmentsFilter;
        }
      }

      const response = await getAPI({
        url: `/question-exams-subject`,
        params,
      });

      return dispatch({
        type: GET_EXAM_SUBJECTS.SUCCESS,
        data: response,
      });
    } catch (error) {
      return dispatch({
        type: GET_EXAM_SUBJECTS.FAILURE,
        error,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

export const getExamTopics =
  (
    filter = {
      subject_group: "",
      subject_title: "",
      department: "",
      subject_topic: "",
    },
    skip = 0,
    limit = 10
  ) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      dispatch({ type: GET_EXAM_TOPICS.REQUEST });

      const params = {
        $skip: skip,
        $limit: limit,
      };
      if (filter?.subject_group !== "") {
        params["subject_group"] = filter.subject_group;
      }
      if (filter?.subject_title !== "") {
        params["subject_title"] = filter.subject_title;
      }
      if (filter?.subject_topic !== "") {
        params["subject_topic"] = filter.subject_topic;
      }
      if (filter?.department !== "") {
        params["department"] = filter.department;
      } else {
        const user = JSON.parse(localStorage.getItem("user"));
        const permission = user?.menu_list;

        if (permission.length > 0) {
          const examCoePermission = permission.find(
            (v) => v.key === "exam_coe"
          );
          const departmentsFilter = examCoePermission.other_list
            .map((v) => v.name)
            .join(",");
          params["department"] = departmentsFilter;
        }
      }

      const response = await getAPI({
        url: `/question-exams-topic`,
        params,
      });

      return dispatch({
        type: GET_EXAM_TOPICS.SUCCESS,
        data: response,
      });
    } catch (error) {
      return dispatch({
        type: GET_EXAM_TOPICS.FAILURE,
        error,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

export const saveFilerExam = (data) => (dispatch) => {
  return dispatch({
    type: SAVE_FILTER_EXAM,
    data,
  });
};
export const savePage = (data) => (dispatch) => {
  return dispatch({
    type: SAVE_PAGE,
    data,
  });
};
export const saveTab = (data) => (dispatch) => {
  return dispatch({
    type: SAVE_TAB,
    data,
  });
};

export const createExamCoE = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_EXAM_COE.REQUEST });

    await postAPI({
      url: `/question-exams/create-question`,
      data,
    });

    return dispatch({
      type: CREATE_EXAM_COE.SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: CREATE_EXAM_COE.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
  }
};

export const uploadFileQuestionExam = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPLOAD_FILE_QUESTION_EXAM.REQUEST });

    const { fullFilePath } = await postAPI({
      url: `/upload-file-question-exams`,
      data,
    });

    dispatch({
      type: UPLOAD_FILE_QUESTION_EXAM.SUCCESS,
    });

    return fullFilePath;
  } catch (error) {
    dispatch({
      type: UPLOAD_FILE_QUESTION_EXAM.FAILURE,
      error,
    });

    return "";
  } finally {
    dispatch(hideLoading());
  }
};

export const getExamTrainCoeList =
  (filter = {}, skip = 0, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      dispatch({ type: GET_EXAM_TRAIN_COE.REQUEST });

      const params = {
        $skip: skip,
        $limit: limit,
        status_adjust: "unadjust",
      };
      if (filter?.code !== "") {
        params["question_code"] = filter.code;
      }
      if (filter?.question !== "") {
        params["question"] = filter.question;
      }
      if (filter?.examOwner !== "") {
        params["creator"] = filter.examOwner;
      }
      if (filter?.groupSubject !== "") {
        params["subject_group"] = filter.groupSubject;
      }
      if (filter?.subject !== "") {
        params["subject_title"] = filter.subject;
      }
      if (filter?.topicName !== "") {
        params["exam_section"] = filter.topicName;
      }
      if (filter?.level !== "") {
        params["difficulty_level"] = filter.level;
      }
      if (filter?.status !== "") {
        params["status"] = filter.status;
      }

      const response = await getAPI({
        url: `/question-exam-trains`,
        params,
      });

      return dispatch({
        type: GET_EXAM_TRAIN_COE.SUCCESS,
        data: response,
      });
    } catch (error) {
      return dispatch({
        type: GET_EXAM_TRAIN_COE.FAILURE,
        error,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

export const getExamTrainCoeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: GET_EXAM_TRAIN_COE_BY_ID.REQUEST });

    const response = await getAPI({
      url: `/question-exam-trains/${id}`,
    });

    return dispatch({
      type: GET_EXAM_TRAIN_COE_BY_ID.SUCCESS,
      data: response,
    });
  } catch (error) {
    return dispatch({
      type: GET_EXAM_TRAIN_COE_BY_ID.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
  }
};

export const editExamTrainCoe = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: EDIT_EXAM_TRAIN_COE.REQUEST });

    await patchAPI({
      url: `/question-exam-trains/${id}`,
      data,
    });

    return dispatch({
      type: EDIT_EXAM_TRAIN_COE.SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: EDIT_EXAM_TRAIN_COE.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
    dispatch(getExamTrainCoeById(id));
  }
};

export const deleteExamTrainCoe = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: DELETE_EXAM_TRAIN_COE.REQUEST });

    await patchAPI({
      url: `/question-exam-trains/delete-question/${id}`,
      data,
    });

    return dispatch({
      type: DELETE_EXAM_TRAIN_COE.SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_EXAM_TRAIN_COE.FAILURE,
      error,
    });
  } finally {
    dispatch(hideLoading());
    dispatch(getExamTrainCoeList());
  }
};
