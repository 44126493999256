import { LOGIN, VERIFY_TOKEN } from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { postAPI } from "utils/api";

export const login = (email, password) => (dispatch) => {
  dispatch(showLoading());

  return postAPI({
    url: "login",
    data: {
      email,
      password,
      strategy: "local",
    },
  })
    .then(({ accessToken, login }) => {
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("user", JSON.stringify(login));
      return dispatch({ type: LOGIN.SUCCESS });
    })
    .catch((error) => dispatch({ type: LOGIN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const verifyToken = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: VERIFY_TOKEN.REQUEST });

    const response = await postAPI({
      url: "/authen-question-exams",
      data,
    });

    return dispatch({
      type: VERIFY_TOKEN.SUCCESS,
      data: response,
    });
  } catch (error) {
    return dispatch({ type: VERIFY_TOKEN.FAILURE });
  } finally {
    dispatch(hideLoading());
  }
};

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
};
