import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import { Stack } from "@mui/material";
import Button from "components/form/Button";
import Table from "components/table/Table";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import PopupAmountExamForTopicExam from "containers/popup/PopupAmountExamForTopicExam";
import PopupCreateExam from "containers/popup/PopupCreateExam";
import PopupEditExam from "containers/popup/PopupEditExam";
import PopupDeleteExam from "containers/popup/PopupDeleteExam";

import { useDispatch, useSelector } from "react-redux";
import { selectLibraryCoe } from "redux/selectors";
import { getExamTopics, getSubjectList } from "redux/actions/library-coe";

const Div = styled.div`
  h2 {
    margin: 0;
  }
  .mb-10-px {
    margin-bottom: 10px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .add-buton {
    background-color: #01b982;
  }

  .field-input {
    width: 32%;
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;
const Divider = styled.div`
  height: 1px;
  background-color: lightgray;
`;

function TopicExam() {
  // external hook
  const dispatch = useDispatch();

  // initiate data
  const initialGroupSubjects = [
    {
      text: "วิชาพื้นฐาน",
      value: "basic",
    },
    {
      text: "วิชาเฉพาะ",
      value: "specific",
    },
  ];
  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };
  const columns = [
    {
      field: "subject_title_no",
      headerName: "รหัสหัวข้อข้อสอบ",
      renderCell: IsNullValue,
      width: 150,
    },
    {
      field: "subject_title",
      headerName: "ชื่อวิชา",
      renderCell: IsNullValue,
      width: 300,
    },
    {
      field: "exam_section",
      headerName: "หัวข้อข้อสอบ",
      renderCell: IsNullValue,
      width: 300,
    },
    {
      field: "subject_group",
      headerName: "หมวดวิชา",
      renderCell: ({ value }) => {
        return value === "basic"
          ? "วิชาพื้นฐาน"
          : value === "specific"
          ? "วิชาเฉพาะ"
          : "-";
      },
      width: 200,
    },
    {
      field: "department",
      headerName: "สาขา",
      renderCell: IsNullValue,
      width: 150,
    },
    {
      field: "question_count",
      headerName: "จำนวนข้อสอบ(ข้อ)",
      width: 130,
    },
  ];

  const { examTopicList, subjectList, total, limit } =
    useSelector(selectLibraryCoe);
  const [examList, setExamList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const fetchExamTopics = useCallback(
    (filter, skip, limit) => {
      dispatch(getExamTopics(filter, skip, limit));
    },
    [dispatch]
  );
  const fetchSubjectList = useCallback(() => {
    dispatch(getSubjectList());
  }, [dispatch]);
  const initiateDepartmentList = () => {
    const { menu_list } = JSON.parse(localStorage.getItem("user"));
    const examCoePermission = menu_list.find((v) => v.key === "exam_coe");

    if (examCoePermission) {
      const { other_list } = examCoePermission;
      const newDepartmentList = other_list.map((v) => ({
        text: v.name,
        value: v.name,
      }));

      setDepartmentList(newDepartmentList);
    }
  };

  useEffect(() => {
    fetchExamTopics();
    fetchSubjectList();
    initiateDepartmentList();
  }, []);
  useEffect(() => {
    const newExamList = examTopicList.map((v, i) => ({
      id: i,
      ...v,
    }));
    setExamList(newExamList);
  }, [examTopicList]);

  // subject list
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    const newSubjects = subjectList.map((v) => ({
      text: v.subject_title,
      value: v.subject_title,
    }));
    setSubjects(newSubjects);
  }, [subjectList]);

  // subject info data
  const [examInfo, setExamInfo] = useState();

  // filter
  const initFilter = {
    subject_group: "",
    department: "",
    subject_title: "",
    subject_topic: "",
  };
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const handleFilterChange = (key, value) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleSearch = () => {
    setPage(0);
    fetchExamTopics(filter, 0, limit);
  };
  const handleClearFilter = () => {
    setPage(0);
    setFilter(initFilter);
    fetchExamTopics(initFilter, 0, limit);
  };
  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    const skipFilter = nextPage * limit;
    fetchExamTopics(filter, skipFilter, limit);
  };
  const handleDeleteExam = (row) => {
    console.log("handleDeleteExam", row);
    setExamInfo(row);
    openDeleteModal();
  };
  const handleEditExam = (row) => {
    console.log("handleEdit", row);
    setExamInfo(row);
    openEditModal();
  };
  const viewAmountExam = ({ row }) => {
    setExamInfo(row);
    openAmountExamModal();
  };

  // amountExamlModal

  const [amountExamModal, setAmountExamModal] = useState(false);
  const openAmountExamModal = () => {
    setAmountExamModal(true);
  };
  const closeAmountExamModal = () => {
    setAmountExamModal(false);
  };

  // create modal
  const [createModal, setCreateModal] = useState(false);
  const openCreateModal = () => {
    setCreateModal(true);
  };
  const closeCreateModal = () => {
    setCreateModal(false);
  };
  const onSubmitCreate = () => {
    closeCreateModal();
  };

  // edit modal
  const [editModal, setEditModal] = useState(false);
  const openEditModal = () => {
    setEditModal(true);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };
  const onSubmitEdit = () => {
    closeEditModal();
  };

  // delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const onSubmitDelete = () => {
    closeDeleteModal();
  };

  return (
    <SidebarLayout title="คลังข้อสอบภาคี">
      <Div>
        <SubMenu
          menu={[
            { path: "/library-coe/examination/all", text: "ข้อสอบ" },
            // { path: "/library-coe/topic-subject/all", text: "รายชื่อวิชา" },
            // { path: "/library-coe/topic-exam/all", text: "หัวข้อข้อสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <Stack
            className="mb-16-px"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2>หัวข้อข้อสอบ ภาคีวิศวกร</h2>
            <Button className="add-buton" onClick={openCreateModal}>
              เพิ่ม หัวข้อข้อสอบ
            </Button>
          </Stack>

          <Divider className="mb-16-px" />

          <Stack
            className="mb-10-px"
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Select
              label="หมวดวิชา"
              items={initialGroupSubjects}
              value={filter.subject_group}
              defaultValue="หมวดวิชา"
              onChange={(e) =>
                handleFilterChange("subject_group", e.target.value)
              }
            />
            <Select
              label="สาขา"
              items={departmentList}
              value={filter.department}
              defaultValue="สาขา"
              onChange={(e) => handleFilterChange("department", e.target.value)}
            />
            <Select
              label="วิชา"
              items={subjects}
              value={filter.subject_title}
              defaultValue="วิชา"
              onChange={(e) =>
                handleFilterChange("subject_title", e.target.value)
              }
            />
          </Stack>

          <Stack
            className="mb-16-px"
            direction="row"
            alignItems="flex-end"
            spacing={1}
          >
            <TextField
              label="Search หัวข้อข้อสอบ"
              value={filter.subject_topic}
              onChange={(e) =>
                handleFilterChange("subject_topic", e.target.value)
              }
              className="field-input"
              required
            />

            <Button onClick={handleSearch}>Search</Button>
            <Button onClick={handleClearFilter} outlined>
              Clear
            </Button>
          </Stack>

          <div className="table-wrapper">
            <div className="mb-16-px">จำนวนหัวข้อข้อสอบ {total} หัวข้อ</div>
            <Table
              columns={columns}
              rows={examList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              onDeleteRow={handleDeleteExam}
              onEditRow={handleEditExam}
            />
          </div>
        </div>

        {/* Modal */}
        <PopupAmountExamForTopicExam
          open={amountExamModal}
          onClose={closeAmountExamModal}
          content={examInfo}
        />
      </Div>
      <PopupCreateExam
        open={createModal}
        onClose={closeCreateModal}
        onSubmit={onSubmitCreate}
      />
      <PopupEditExam
        open={editModal}
        onClose={closeEditModal}
        onSubmit={onSubmitEdit}
        content={examInfo}
      />
      <PopupDeleteExam
        open={deleteModal}
        onClose={closeDeleteModal}
        onSubmit={onSubmitDelete}
        content={examInfo}
      />
    </SidebarLayout>
  );
}

export default TopicExam;
