import React from "react";
import styled from "styled-components";
import { Button as ButtonMui } from "@material-ui/core";

const StyledButton = styled(ButtonMui)`
  padding: 6px 20px;
  /* background: #000 !important; */
  background-color: #a81e21;

  &.Mui-disabled {
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26);
  }

  .MuiButton-label {
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
    color: #fff;
  }
`;

const ButtonBlack = ({ className, ...props }) => {
  return <StyledButton className={className} variant="contained" {...props} />;
};

export default ButtonBlack;
