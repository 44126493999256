import React from "react";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import { Stack } from "@mui/material";
import TextField from "components/form/TextField";

const Div = styled.div`
  .text-center {
    text-align: center;
  }

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }

  h2,
  p {
    margin: 0;
  }

  .button-outline {
    border: 1px solid #982436;
    background-color: #fff;
    color: #982436;
  }
`;

function PopupDeleteSubject({ open, onClose, onSubmit, content }) {
  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <h2 className="text-center mb-20-px">ลบรายการนี้หรือไม่</h2>
        <Stack
          className="mb-16-px"
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <TextField
            label="รหัสวิชา"
            value={content?.subject_no}
            // onChange={(e) => setTopicName(e.target.value)}
            className="field-input"
            disabled={true}
            required
          />
          <TextField
            label="ชื่อวิชา"
            value={content?.subject_name}
            // onChange={(e) => setTopicName(e.target.value)}
            className="field-input"
            disabled={true}
            required
          />
        </Stack>

        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button className="button-outline" onClick={onClose}>
            ยกเลิก
          </Button>
          <Button onClick={onSubmit}>ยืนยัน</Button>
        </Stack>
      </Div>
    </BaseModal>
  );
}

export default PopupDeleteSubject;
