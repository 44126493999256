import React, { useState } from "react";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import { Stack } from "@mui/material";
import TextField from "components/form/TextField";
import Select from "components/form/Select";

const Div = styled.div`
  .text-center {
    text-align: center;
  }

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }

  h2,
  p {
    margin: 0;
  }

  .button-outline {
    border: 1px solid #982436;
    background-color: #fff;
    color: #982436;
  }
`;

function PopupCreateSubject({ open, onClose, onSubmit }) {
  // form
  const [formCreate, setFormCreate] = useState();

  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <h2 className="text-center mb-20-px">เพิ่มรายชื่อวิชา</h2>
        <Stack
          className="mb-16-px"
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <TextField
            label="รหัสวิชา"
            // value={topicName}
            // onChange={(e) => setTopicName(e.target.value)}
            className="field-input"
            required
          />
          <TextField
            label="ชื่อวิชา"
            // value={topicName}
            // onChange={(e) => setTopicName(e.target.value)}
            className="field-input"
            required
          />
        </Stack>

        <Select
          className="mb-16-px"
          label="หมวดวิชาทั้งหมด"
          // items={dataSubject}
          // value={subjectId}
          defaultValue="หมวดวิชาทั้งหมด"
          // onChange={(e) => handleSubjectChange(e.target.value)}
        />
        <Select
          className="mb-16-px"
          label="สาขาวิชาทั้งหมด"
          // items={dataSubject}
          // value={subjectId}
          defaultValue="หมวดวิชาทั้งหมด"
          // onChange={(e) => handleSubjectChange(e.target.value)}
        />

        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button className="button-outline" onClick={onClose}>
            ยกเลิก
          </Button>
          <Button onClick={onSubmit}>ยืนยัน</Button>
        </Stack>
      </Div>
    </BaseModal>
  );
}

export default PopupCreateSubject;
