import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { checkValue } from "utils/common";
import { displayDate } from "utils/formatValue";

import styled from "styled-components";
import InlineSpaceText from "components/form/InlineSpaceText";
import SidebarLayout from "components/layout/SidebarLayout";
import { Grid } from "@material-ui/core";
import { ArrowBack as BackIcon, Edit as EditIcon } from "@material-ui/icons";
import Paper from "components/surfaces/Paper";
import Divider from "components/surfaces/Divider";
// import SubMenu from "components/layout/SubMenu";

// import PopupExamQuestionUpdateCoE from "containers/popup/PopupExamQuestionUpdateCoE";
// import PopupExamQuestionStatusUpdateCoE from "containers/popup/PopupExamQuestionStatusUpdateCoE";
// import PopupEditExamQuestionChoiceCoE from "containers/popup/PopupEditExamQuestionChoiceCoE";

import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import ReactHtmlParser from "react-html-parser";

import { useDispatch, useSelector } from "react-redux";
import { getExamTrainCoeById } from "redux/actions/library-coe";
import { selectLibraryCoe } from "../../redux/selectors";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;

    :disabled {
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
  }

  .question-img {
    max-width: 350px;
    padding: 10px 0;
    img {
      width: 100%;
    }
  }
  .question-item {
    display: flex;
    margin: 15px;
    .question-title {
      width: 200px;
      margin-left: 10px;
    }
    .question-detail {
      width: 100%;
    }
  }
`;

const ExaminationDetailTrainCoE = () => {
  // external hook
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  // initial data
  const { examTrainCoeDetail } = useSelector(selectLibraryCoe);
  // const [canEditQuestion, setCanEditQuestion] = useState(false);

  const getExamCoeDetail = useCallback(() => {
    dispatch(getExamTrainCoeById(id));
  }, [id, dispatch]);
  // const initPermissionEdit = () => {
  //   const { menu_list } = JSON.parse(localStorage.getItem("user"));
  //   const examCoePermission = menu_list.find((v) => v.key === "exam_coe");

  //   if (examCoePermission) {
  //     const { other_list } = examCoePermission;
  //     const isCanEditQuestion = other_list.some(
  //       (v) => v.key === "edit_quiz_exam"
  //     );

  //     setCanEditQuestion(isCanEditQuestion);
  //   }
  // };

  useEffect(() => {
    getExamCoeDetail();
    // initPermissionEdit();
  }, []);

  // pop up
  // const [isOpenPopupExamQuestionUpdate, setIsOpenPopupExamQuestionUpdate] =
  //   useState(false);
  // const [isOpenPopupEditQuestion, setIsOpenPopupEditQuestion] = useState(false);
  // const [
  //   isOpenPopupExamQuestionStatusUpdate,
  //   setIsOpenPopupExamQuestionStatusUpdate,
  // ] = useState(false);

  // const handleEditQuestion = (row) => {
  //   setIsOpenPopupEditQuestion(true);
  // };
  // const handleCloseEditQuestion = () => {
  //   setIsOpenPopupEditQuestion(false);
  // };
  // const handleExamQuestionUpdate = () => {
  //   setIsOpenPopupExamQuestionUpdate(true);
  // };
  // const handleCloseExamQuestionUpdate = () => {
  //   setIsOpenPopupExamQuestionUpdate(false);
  // };
  // const handleExamQuestionStatusUpdate = (row) => {
  //   setIsOpenPopupExamQuestionStatusUpdate(true);
  // };
  // const handleCloseExamQuestionStatusUpdate = () => {
  //   setIsOpenPopupExamQuestionStatusUpdate(false);
  // };

  // function about convert question data
  const checkStringFormat = (str) => {
    try {
      const json = JSON.parse(str);

      if (Array.isArray(json) && json.length > 0 && "insert" in json[0]) {
        return "json";
      }
    } catch (error) {}

    if (/https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g.test(str)) {
      return "html-img";
    }
    if (/<[a-z][\s\S]*>/i.test(str)) {
      return "html";
    }

    return "string";
  };
  const convertQuestion = (value) => {
    const formatString = checkStringFormat(value);
    const result =
      formatString === "string" || formatString === "html"
        ? ConvertHtmlToString(value)
        : formatString === "html-img"
        ? ConvertHtmlToImage(value)
        : deltaToHtml(value);
    return result;
  };
  const ConvertHtmlToString = (value) => {
    if (value === "") {
      return "-";
    }
    return <div>{ReactHtmlParser(value)}</div>;
  };
  const ConvertHtmlToImage = (value) => {
    if (value === "") {
      return "-";
    }
    const regex = /https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g;
    const result = value.replace(regex, '<img src="$&" />');

    return <div>{ReactHtmlParser(result)}</div>;
  };
  const ConvertHtmlToImageForFirebaseImage = (value) => {
    if (value === "") {
      return "-";
    }
    const regex =
      /https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)(\?\S+)?/g;
    const result = value.replace(regex, '<img src="$&" />');

    return <div>{ReactHtmlParser(result)}</div>;
  };
  const deltaToHtml = (value) => {
    const correctFormatJsonString = value.replace(/\n/g, "\\n");
    const deltaObject = JSON.parse(correctFormatJsonString);
    const converter = new QuillDeltaToHtmlConverter(deltaObject, {});

    const htmlString = converter.convert();
    const html = ConvertHtmlToString(htmlString);

    return html;
  };

  return (
    <SidebarLayout title="คลังข้อสอบภาคี">
      {/* {Boolean(isOpenPopupExamQuestionUpdate) && (
        <PopupExamQuestionUpdateCoE
          open
          onClose={handleCloseExamQuestionUpdate}
          id={id}
        />
      )}
      {Boolean(isOpenPopupExamQuestionStatusUpdate) && (
        <PopupExamQuestionStatusUpdateCoE
          open
          onClose={handleCloseExamQuestionStatusUpdate}
          id={id}
        />
      )}
      {Boolean(isOpenPopupEditQuestion) && (
        <PopupEditExamQuestionChoiceCoE
          open
          onClose={handleCloseEditQuestion}
          id={id}
        />
      )} */}
      <Div>
        <div className="content-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} className="headbar-panel-wrapper">
              <BackIcon
                className="back-icon"
                onClick={() =>
                  history.push("/library-train-coe/examination/all")
                }
              />
              <span className="title">{examTrainCoeDetail?.question_code}</span>
            </Grid>

            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid
                    item
                    spacing={1}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item alignItems="center" container xs={7}>
                      ข้อมูลเกี่ยวกับข้อสอบ
                    </Grid>
                    {/* <Grid item xs={5} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText className="fix-height" label="สาขา">
                    <span>{checkValue(examTrainCoeDetail?.department)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="หมวดวิชา">
                    <span>
                      {examTrainCoeDetail?.subject_group === "basic"
                        ? "วิชาพื้นฐาน"
                        : examTrainCoeDetail?.subject_group === "specific"
                        ? "วิชาเฉพาะ"
                        : "-"}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="วิชา">
                    <span>{checkValue(examTrainCoeDetail?.subject_title)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="หัวข้อ ">
                    <span>{checkValue(examTrainCoeDetail?.exam_section)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="ระดับความยาก">
                    <span>
                      {examTrainCoeDetail?.difficulty_level === "easy"
                        ? "ง่าย"
                        : examTrainCoeDetail?.difficulty_level === "medium"
                        ? "กลาง"
                        : examTrainCoeDetail?.difficulty_level === "hard"
                        ? "ยาก"
                        : "-"}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText className="fix-height" label="Taxonomy">
                    <span>{checkValue(examTrainCoeDetail?.taxonomy)}</span>
                  </InlineSpaceText>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid container spacing={1} direction="column" item>
                  <Grid
                    item
                    xs={12}
                    spacing={1}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="headline"
                  >
                    <Grid item xs={7}>
                      สถานะข้อสอบ
                    </Grid>
                    {/* <Grid item xs={5} container justifyContent="flex-end">
                      <Button
                        className="btn-edit"
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => handleExamQuestionStatusUpdate()}
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <InlineSpaceText
                    label="ชื่อผู้นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(examTrainCoeDetail?.import_person)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่นำเข้าข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(displayDate(examTrainCoeDetail?.import_date))}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="ชื่อผู้อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(examTrainCoeDetail?.verify_person)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="ชื่อผู้ออกข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(examTrainCoeDetail?.creator)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="คณะกรรมการสมัยที่ออกข้อสอบ"
                    className="fix-height "
                  >
                    <span>{checkValue(examTrainCoeDetail?.committee)}</span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="สถานะการปรับปรุงข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {examTrainCoeDetail?.status_adjust === "adjust"
                        ? "ปรับแล้ว"
                        : examTrainCoeDetail?.status_adjust === "unadjust"
                        ? "ยังไม่ปรับ"
                        : "-"}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText
                    label="วันที่อนุมัติข้อสอบ"
                    className="fix-height "
                  >
                    <span>
                      {checkValue(
                        displayDate(examTrainCoeDetail?.approved_date)
                      )}
                    </span>
                  </InlineSpaceText>
                  <InlineSpaceText label="สถานะข้อสอบ" className="fix-height ">
                    <span>{checkValue(examTrainCoeDetail?.status)}</span>
                  </InlineSpaceText>
                  <br />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid container spacing={1} item direction="column">
                  <Grid item spacing={1} xs={12} container alignItems="center">
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <h3 style={{ marginRight: "5px" }}>โจทย์: </h3>
                      {/* {canEditQuestion && (
                        <Button
                          className="btn-edit"
                          startIcon={<EditIcon />}
                          variant="outlined"
                          onClick={() => handleEditQuestion()}
                        >
                          แก้ไขข้อมูล
                        </Button>
                      )} */}
                    </Grid>
                    <Grid item xs={12} container>
                      <div>
                        <span id="section_question">
                          {convertQuestion(examTrainCoeDetail?.question)}
                        </span>
                        <br />
                        {examTrainCoeDetail?.question_image && (
                          <span>
                            {ConvertHtmlToImageForFirebaseImage(
                              examTrainCoeDetail?.question_image
                            )}
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <div className="question-container">
                    <div className="question-item">
                      <div className="question-title">คำตอบที่ 1 </div>
                      <div className="question-detail">
                        <span>
                          {examTrainCoeDetail?.c1 !== "" &&
                            convertQuestion(examTrainCoeDetail?.c1)}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">คำตอบที่ 2</div>
                      <div className="question-detail">
                        <span>
                          {examTrainCoeDetail?.c2 !== "" &&
                            convertQuestion(examTrainCoeDetail?.c2)}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">คำตอบที่ 3</div>
                      <div className="question-detail">
                        <span>
                          {examTrainCoeDetail?.c3 !== "" &&
                            convertQuestion(examTrainCoeDetail?.c3)}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">คำตอบที่ 4</div>
                      <div className="question-detail">
                        <span>
                          {examTrainCoeDetail?.c4 !== "" &&
                            convertQuestion(examTrainCoeDetail?.c4)}
                        </span>
                        <br />
                      </div>
                    </div>

                    <div className="question-item">
                      <div className="question-title">คำตอบที่ถูกต้อง</div>
                      <div className="question-detail">
                        <span>{checkValue(examTrainCoeDetail.answer)}</span>
                      </div>
                    </div>
                    <div className="question-item">
                      <div className="question-title">Random คำตอบ</div>
                      <div className="question-detail">
                        <span>
                          {examTrainCoeDetail.is_random_answer === 1
                            ? "ใช่"
                            : "ไม่ใช่"}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationDetailTrainCoE;
