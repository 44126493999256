import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SlideAlert from "containers/SlideAlert";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Home from "pages/Home";

import ReportWelcome from "pages/report/ReportWelcome";
import ReportManage from "pages/report/ReportManage";
import ReportFinance from "pages/report/ReportFinance";
import ReportInternational from "pages/report/ReportInternational";
import ReportBuilding from "pages/report/ReportBuilding";
import ReportCorporateCommu from "pages/report/ReportCorporateCommu";
import ReportOfficeCoucil from "pages/report/ReportOfficeCoucil";
import ReportTech from "pages/report/ReportTech";
import ReportLicense from "pages/report/ReportLicense";
import ReportLawEthic from "pages/report/ReportLawEthic";
import ReportEducationStandard from "pages/report/ReportEducationStandard";

import ExaminerDetail from "pages/centerLibrary/ExaminerDetail";
import ExaminationAll from "pages/centerLibrary/ExaminationAll";
import ExamSeriesAll from "pages/centerLibrary/ExamSeriesAll";
import ExamSeriesCreate from "pages/centerLibrary/ExamSeriesCreate";
import ExaminationDetail from "pages/centerLibrary/ExaminationDetail";

import TicketAll from "pages/callCenter/TicketAll";
import CallCenterAccount from "pages/callCenter/CallCenterAccount";
import TicketDetail from "pages/callCenter/TicketDetail";
import CustomerDetail from "pages/callCenter/CustomerDetail";
import CallCenterWelcome from "pages/callCenter/CallCenterWelcome";

import SecureRoute from "./SecureRoute";
import {
  getMasterDataProvince,
  getMasterDataDistrict,
  getMasterDataSubDistrict,
  getMasterDataBank,
  getMasterDataNationality,
  getMasterDataSubject,
  getMasterDataJobs,
  getMasterDataCOEReport,
  getMasterDataCOESubReport,
} from "redux/actions/masterData";
import LibaryWelcome from "pages/centerLibrary/LibaryWelcome";
import ExaminerAll from "pages/centerLibrary/ExaminerAll";
import ReportExclusiveCoucil from "pages/report/ReportExclusiveCoucil";
import AccountingWelcome from "pages/accounting/AccountingWelcome";
import DairyIncome from "pages/accounting/DairyIncome";
import Refund from "pages/accounting/Refund";
import Reconcile from "pages/accounting/Reconcile";
import Post_GL from "pages/accounting/Post_GL";
import VerifyPayment from "pages/accounting/VerifyPayment";
import UploadPayment from "pages/accounting/UploadPayment";
import PaymentAll from "pages/accounting/PaymentAll";
import PaymentCash from "pages/accounting/PaymentCash";
import PaymentDelivery from "pages/accounting/PaymentDelivery";
import NotificationAll from "pages/notification/NotificationAll";
import NotificationWelcome from "pages/notification/NotificationWelcome";
import NotificationCustomAll from "pages/notification/NotificationCustomAll";
import NotificationReport from "pages/notification/NotificationReport";
import TopicSubject from "pages/centerLibrary/TopicSubject";
import TopicExam from "pages/centerLibrary/TopicExam";

import LibaryCoEWelcome from "pages/centerLibrary/LibaryCoEWelcome";
import ExaminationCoEAll from "pages/centerLibrary/ExaminationCoEAll";
import ExaminationDetailCoE from "pages/centerLibrary/ExaminationDetailCoE";
import LibaryTrainCoEWelcome from "pages/centerLibrary/LibaryTrainCoEWelcome";
import ExaminationTrainCoEAll from "pages/centerLibrary/ExaminationTrainCoEAll";
import ExaminationDetailTrainCoE from "pages/centerLibrary/ExaminationDetailTrainCoE";

const Router = () => {
  const accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessToken) {
      dispatch(getMasterDataProvince());
      dispatch(getMasterDataDistrict());
      dispatch(getMasterDataSubDistrict());
      dispatch(getMasterDataBank());
      dispatch(getMasterDataNationality());
      dispatch(getMasterDataSubject());
      dispatch(getMasterDataJobs());
      dispatch(getMasterDataCOEReport());
      dispatch(getMasterDataCOESubReport());
    }
  }, [accessToken, dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <SecureRoute path="/" component={LibaryCoEWelcome} exact />
        <SecureRoute path="/library" component={LibaryWelcome} exact />
        <SecureRoute path="/report" component={ReportWelcome} exact />
        <SecureRoute path="/accounting" component={AccountingWelcome} exact />

        <SecureRoute
          path="/library/examiner/all"
          component={ExaminerAll}
          exact
        />
        <SecureRoute
          path="/library/exminer/detail/:id"
          component={ExaminerDetail}
          exact
        />
        <SecureRoute
          path="/library/examination/all"
          component={ExaminationAll}
          exact
        />
        <SecureRoute
          path="/library/examination/detail/:id"
          component={ExaminationDetail}
          exact
        />
        <SecureRoute
          path="/library/examseries/all"
          component={ExamSeriesAll}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type"
          component={ExamSeriesCreate}
          exact
        />
        <SecureRoute
          path="/library/examseries/:type/:id"
          component={ExamSeriesCreate}
          exact
        />
        <SecureRoute
          path="/library/topic-subject/all"
          component={TopicSubject}
          exact
        />
        <SecureRoute
          path="/library/topic-exam/all"
          component={TopicExam}
          exact
        />
        {/* libray coe */}
        <SecureRoute path="/library-coe" component={LibaryCoEWelcome} exact />
        <SecureRoute
          path="/library-coe/examination/all"
          component={ExaminationCoEAll}
          exact
        />
        <SecureRoute
          path="/library-coe/examination/detail/:id"
          component={ExaminationDetailCoE}
          exact
        />
        <SecureRoute
          path="/library-coe/topic-subject/all"
          component={TopicSubject}
          exact
        />
        <SecureRoute
          path="/library-coe/topic-exam/all"
          component={TopicExam}
          exact
        />

        {/* train */}
        <SecureRoute
          path="/library-train-coe"
          component={LibaryTrainCoEWelcome}
          exact
        />
        <SecureRoute
          path="/library-train-coe/examination/all"
          component={ExaminationTrainCoEAll}
          exact
        />
        <SecureRoute
          path="/library-train-coe/examination/detail/:id"
          component={ExaminationDetailTrainCoE}
          exact
        />

        <SecureRoute path="/report/manage" component={ReportManage} exact />
        <SecureRoute path="/report/finance" component={ReportFinance} exact />
        <SecureRoute
          path="/report/international"
          component={ReportInternational}
          exact
        />
        <SecureRoute path="/report/building" component={ReportBuilding} exact />
        <SecureRoute
          path="/report/corporate_communication"
          component={ReportCorporateCommu}
          exact
        />
        <SecureRoute
          path="/report/office_coucil"
          component={ReportOfficeCoucil}
          exact
        />
        <SecureRoute
          path="/report/exclusive_coucil"
          component={ReportExclusiveCoucil}
          exact
        />
        <SecureRoute path="/report/tech" component={ReportTech} exact />
        <SecureRoute path="/report/license" component={ReportLicense} exact />
        <SecureRoute
          path="/report/law_ethic"
          component={ReportLawEthic}
          exact
        />
        <SecureRoute
          path="/report/edu_standard"
          component={ReportEducationStandard}
          exact
        />
        <SecureRoute
          path="/accounting/dairy-income"
          component={DairyIncome}
          exact
        />
        <SecureRoute path="/accounting/reconcile" component={Reconcile} exact />
        <SecureRoute path="/accounting/post-gl" component={Post_GL} exact />
        <SecureRoute
          path="/accounting/upload-payment"
          component={UploadPayment}
          exact
        />
        <SecureRoute
          path="/accounting/verify-payment"
          component={VerifyPayment}
          exact
        />
        <SecureRoute
          path="/accounting/payment-all"
          component={PaymentAll}
          exact
        />
        <SecureRoute
          path="/accounting/payment-cash"
          component={PaymentCash}
          exact
        />
        <SecureRoute
          path="/accounting/payment-delivery"
          component={PaymentDelivery}
          exact
        />
        <SecureRoute path="/accounting/refund" component={Refund} exact />

        <SecureRoute
          path="/notification"
          component={NotificationWelcome}
          exact
        />
        <SecureRoute
          path="/notification/all"
          component={NotificationAll}
          exact
        />
        <SecureRoute
          path="/notification/custom/all"
          component={NotificationCustomAll}
          exact
        />
        <SecureRoute
          path="/notification/report"
          component={NotificationReport}
          exact
        />

        <SecureRoute path="/call-center" component={CallCenterWelcome} exact />
        <SecureRoute
          path="/call-center/ticket/all"
          component={TicketAll}
          exact
        />
        <SecureRoute
          path="/call-center/ticket/detail/:id"
          component={TicketDetail}
          exact
        />
        <SecureRoute
          path="/call-center/ticket/member/:id"
          component={CustomerDetail}
          exact
        />
        <SecureRoute
          path="/call-center/account"
          component={CallCenterAccount}
          exact
        />
      </Switch>
      <SlideAlert />
    </BrowserRouter>
  );
};

export default Router;
