import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_COE_WEB } from "configs/app";

import { validateEmail } from "utils/validation";

import { login, logout, verifyToken } from "redux/actions/auth";

import styled from "styled-components";
import TextField from "components/form/TextField";
import Button from "components/form/Button";

import imageLogo from "assets/images/logo-Engineers.png";
import imageBackground from "assets/images/login-bg.png";

const Div = styled.div`
  display: flex;
  height: 100vh;
  background: url("${imageBackground}") no-repeat center center;
  background-size: cover;

  > .left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a81e21;
    font-weight: bold;

    img {
      width: 440px;
    }
  }

  > .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-wrapper {
      width: 560px;
      padding: 63px 82px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.04);

      .title {
        font-size: 44px;
        font-family: "kelson_sansbold";
        text-align: center;
        margin-top: 0;
        color: #a81e21;
      }

      .sub-title {
        text-align: center;
        margin-top: 0;
      }

      form {
        margin-top: 60px;
      }

      .field-password {
        margin-top: 24px;
      }

      .bt-login {
        margin-top: 24px;
      }
    }
  }
`;

const Login = () => {
  // external hook
  const dispatch = useDispatch();
  const history = useHistory();

  // initial
  useEffect(() => {
    const customURLSearchParams = (url) => {
      var params = {};
      // ตรวจสอบว่า URL มี "?" หรือไม่
      if (url.indexOf("?") !== -1) {
        var queryString = url.split("?")[1];
        var paramPairs = queryString.split("&");

        paramPairs.forEach(function (pair) {
          var keyValue = pair.split("=");
          var key = decodeURIComponent(keyValue[0]);
          var value = decodeURIComponent(keyValue[1]);
          params[key] = value;
        });
      }

      return {
        get: function (param) {
          return params[param] || null;
        },
        getAll: function () {
          return params;
        },
      };
    };

    // ดึง URL ปัจจุบัน
    let currentURL = window.location.href;
    // แยก URL เพื่อดึงพารามิเตอร์
    let urlParams = customURLSearchParams(currentURL);
    // ดึงค่าของพารามิเตอร์ "token"
    let tokenValue = urlParams.get("token");

    if (tokenValue) {
      const request = {
        tokenadmin: tokenValue,
      };
      dispatch(verifyToken(request)).then((res) => {
        const { type, data } = res;
        if (type.endsWith("_SUCCESS")) {
          const { first_name, last_name, email, menu_list, position_name } =
            data;
          const newUserInfoLogin = {
            email,
            is_email_verify: 0,
            first_name,
            last_name,
            menu_list,
            position_name,
          };
          localStorage.setItem("access_token", tokenValue);
          localStorage.setItem("user", JSON.stringify(newUserInfoLogin));

          history.replace("/library-coe/examination/all");
        } else {
          logout();
          window.location.replace(BASE_COE_WEB);
        }
      });
    } else {
      logout();
      window.location.replace(BASE_COE_WEB);
    }
  }, []);

  // email,password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(login(email, password)).then(() => {
        history.replace("/library-coe");
      });
    },
    [dispatch, email, history, password]
  );

  return (
    <Div>
      <div className="left">
        <img className="logo" src={imageLogo} />
      </div>
      <div className="right">
        <div className="login-wrapper">
          <div className="title">เข้าสู่ระบบ</div>
          <p className="sub-title">สำหรับเจ้าหน้าที่สภาวิศวกร</p>
          <form onSubmit={handleLogin}>
            <TextField
              className="field-email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              className="field-password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              className="bt-login"
              type="submit"
              disabled={!validateEmail(email) || !password}
              fullWidth
            >
              เข้าสู่ระบบ
            </Button>
          </form>
        </div>
      </div>
    </Div>
  );
};

export default Login;
