export const selectLoading = (state) => state.loading;

export const selectAlert = (state) => state.alert;

export const selectLibraryCoe = (state) => state.libraryCoe;

export const selectMasterData = (type) => (state) => state.masterData[type];

export const selectMasterDataProvince = selectMasterData("proviceData");
export const selectMasterDataDistrict = selectMasterData("districtData");
export const selectMasterDataSubDistrict = selectMasterData("subDistrictData");

export const selectMasterDataBank = selectMasterData("bankData");
export const selectMasterDataNationality = selectMasterData("nationalityData");
export const selectMasterDataTag = selectMasterData("tagData");
export const selectMasterDataMemberStatus =
  selectMasterData("memberStatusData");
export const selectMasterSubject = selectMasterData("subjectData");
export const selectMasterJob = selectMasterData("jobData");
