import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "quill/dist/quill.snow.css";

import styled from "styled-components";
import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
// import { Checkbox } from "@material-ui/core";
// import PopupApproveExamQuestion from "containers/popup/PopupApproveExamQuestion";
// import PopupManageExamQuestion from "containers/popup/PopupManageExamQuestion";
// import PopupCreateExamCoE from "containers/popup/PopupCreateExamCoE";

import { useDispatch, useSelector } from "react-redux";
import { selectLibraryCoe } from "../../redux/selectors";
import {
  getExamTrainCoeList,
  // deleteExamCoe,
  saveFilerExam,
  savePage,
} from "redux/actions/library-coe";

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
  }
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .truncate {
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const ExaminationTrainCoEAll = () => {
  // external hook
  const dispatch = useDispatch();
  const history = useHistory();

  // initial data
  // initial columns
  // const handleConfirmChange = (value) => {
  //   setSelectedId((prev) => {
  //     let isExisted = prev.map((it) => it.id).includes(value?.id);

  //     let newSelected = isExisted
  //       ? prev.filter((it) => it.id !== value?.id)
  //       : [...prev, value];
  //     return newSelected;
  //   });
  // };
  // const handleCheckAll = () => {
  //   setSelectedAll(!selectedAll);
  // };
  const gotoExamDetail = (id) => {
    dispatch(saveFilerExam(filter));
    dispatch(savePage(skip / limit));
    history.push(`/library-train-coe/examination/detail/${id}`);
  };
  const LinkDetailCell = ({ value, row }) => {
    return (
      <div className="link" onClick={() => gotoExamDetail(row?.id)}>
        {value}
      </div>
    );
  };
  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };
  const ApproveButton = ({ row }) => {
    return <Button onClick={() => handleApprove(row)}>อนุมัติ</Button>;
  };
  const handleApprove = (row) => {
    // let obj = {
    //   questions: [
    //     {
    //       id: row?.id,
    //       status: "active",
    //     },
    //   ],
    // };
    // setExamQuestionObj(obj);
    // setIsOpenApproveModal(true);
  };
  const checkStringFormat = (str) => {
    try {
      const json = JSON.parse(str);

      if (Array.isArray(json) && json.length > 0 && "insert" in json[0]) {
        return "json";
      }
    } catch (error) {}

    if (/https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g.test(str)) {
      return "html-img";
    }
    if (/<[a-z][\s\S]*>/i.test(str)) {
      return "html";
    }

    return "string";
  };
  const ConvertHtmlToImageForFirebaseImage = (value) => {
    if (value === "") {
      return "-";
    }
    const regex =
      /https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)(\?\S+)?/g;
    const result = value.replace(regex, '<img src="$&" />');

    return <div>{ReactHtmlParser(result)}</div>;
  };
  const convertQuestion = ({ value, row }) => {
    if (value === '[{"insert":"\\n"}]') {
      const result = ConvertHtmlToImageForFirebaseImage(row?.question_image);
      return result;
    } else {
      const formatString = checkStringFormat(value);
      const result =
        formatString === "string" || formatString === "html"
          ? ConvertHtmlToString(value)
          : formatString === "html-img"
          ? ConvertHtmlToImage(value)
          : deltaToHtml(value);

      return result;
    }
  };
  const ConvertHtmlToString = (value) => {
    if (value === "") {
      return "-";
    }
    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };
  const ConvertHtmlToImage = (value) => {
    if (value === "") {
      return "-";
    }

    const regex = /https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g;
    const result = value.replace(regex, '<img src="$&" />');

    return <div>{ReactHtmlParser(result)}</div>;
  };
  const deltaToHtml = (value) => {
    const correctFormatJsonString = value.replace(/\n/g, "\\n");
    const deltaObject = JSON.parse(correctFormatJsonString);
    const converter = new QuillDeltaToHtmlConverter(deltaObject, {});

    const htmlString = converter.convert();
    const html = ConvertHtmlToString(htmlString);

    return html;
  };
  const columns = [
    // {
    //   field: "checkbox",
    //   headerName: "",
    //   renderCell: ({ row }) => (
    //     <Checkbox
    //       checked={selectedId.map((it) => it?.id).includes(row?.id)}
    //       onChange={() => handleConfirmChange(row)}
    //     />
    //   ),
    //   renderHeader: ({ row }) => (
    //     <Checkbox checked={selectedAll} onChange={() => handleCheckAll()} />
    //   ),
    // },
    {
      field: "id",
      headerName: "ลำดับ",
      width: 100,
    },
    {
      field: "question_code",
      headerName: "รหัสข้อสอบ",
      renderCell: LinkDetailCell,
      width: 200,
    },
    {
      field: "question",
      headerName: "โจทย์",
      width: 500,
      renderCell: convertQuestion,
    },
    {
      field: "subject_group",
      headerName: "หมวดวิชา",
      renderCell: ({ value }) => value,
      width: 200,
    },
    {
      field: "subject_title",
      headerName: "วิชา",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "taxonomy",
      headerName: "Taxonomy",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "difficulty_level",
      headerName: "ระดับความยาก",
      renderCell: ({ value }) =>
        value === "easy"
          ? "ง่าย"
          : value === "medium"
          ? "กลาง"
          : value === "hard"
          ? "ยาก"
          : "-",
      width: 200,
    },
    {
      field: "creator",
      headerName: "ชื่อผู้ออกข้อสอบ",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "created_date",
      headerName: "วันที่สร้างข้อสอบ",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "is_enable",
      headerName: "การเปิดใช้งาน",
      width: 200,
      renderCell: ({ value }) =>
        value === 1 ? "เปิดใช้งาน" : "ยังไม่เปิดใช้งาน",
    },
    {
      field: "",
      headerName: "Verify",
      width: 200,
      renderCell: ApproveButton,
    },
    {
      field: "status",
      headerName: "สถานะ",
      width: 200,
    },
  ];

  // old code
  // const [examQuestionObj, setExamQuestionObj] = useState();
  // const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  // const [selectedId, setSelectedId] = useState([]);
  // const [selectedAll, setSelectedAll] = useState(false);

  const {
    examTrainCoeList,
    keepedFilterExam,
    keepedPage,
    total,
    limit,
    skip,
    // state,
    // isLoading,
  } = useSelector(selectLibraryCoe);
  const [subjects, setSubjects] = useState([]);

  const initialGroupSubjects = [
    {
      text: "อบรมทดสอบความพร้อม",
      value: "อบรมทดสอบความพร้อม",
    },
  ];
  const difficultyLevelList = [
    { value: "easy", text: "ง่าย" },
    { value: "medium", text: "กลาง" },
    { value: "hard", text: "ยาก" },
  ];
  const statusList = [
    { text: "Active", value: "active" },
    { text: "Pending", value: "pending" },
  ];

  const fetchExamTrainCoeList = useCallback(
    (filter = {}, skip = 0, limit = 10) => {
      dispatch(getExamTrainCoeList(filter, skip, limit));
    },
    [dispatch]
  );
  const initiateSubject = () => {
    const subjectList = [
      {
        text: "สิ่งแวดล้อม",
        value: "สิ่งแวดล้อม",
      },
      {
        text: "จรรยาบรรณ",
        value: "จรรยาบรรณ",
      },
      {
        text: "กฎหมาย",
        value: "กฎหมาย",
      },
      {
        text: "ความปลอดภัย",
        value: "ความปลอดภัย",
      },
    ];
    setSubjects(subjectList);
  };

  useEffect(() => {
    initiateSubject();
  }, []);

  // exam question list
  const [examQuestionList, setExamQuestionList] = useState([]);

  useEffect(() => {
    setExamQuestionList(examTrainCoeList);
  }, [examTrainCoeList]);
  useEffect(() => {
    setFilter(keepedFilterExam);
    const skipFilter = keepedPage * limit;
    fetchExamTrainCoeList(keepedFilterExam, skipFilter, limit);
  }, [keepedFilterExam]);
  // useEffect(() => {
  //   if (isLoading === false && state === "CREATE_EXAM_COE.SUCCESS") {
  //     fetchExamTrainCoeList(initialFilter, 0, limit);
  //   }
  // }, [state, isLoading]);

  // filter
  const initialFilter = {
    code: "",
    question: "",
    examOwner: "",
    groupSubject: "",
    subject: "",
    topicName: "",
    level: "",
    status: "",
    statusAdjust: "",
  };
  const [filter, setFilter] = useState(initialFilter);

  const handlePageChange = (nextPage) => {
    const skipFilter = nextPage * limit;
    fetchExamTrainCoeList(filter, skipFilter, limit);
  };
  const handleSearch = () => {
    fetchExamTrainCoeList(filter, 0, limit);
  };
  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };
  const handleClearFilter = () => {
    setFilter(initialFilter);
    fetchExamTrainCoeList(initialFilter, 0, limit);
  };

  // create exam modal
  // const [isOpenCreateExamModal, setIsOpenCreateExamModal] = useState(false);

  // const handleCreateExam = () => {
  //   setIsOpenCreateExamModal(true);
  // };
  // const handleCloseCreateExam = () => {
  //   setIsOpenCreateExamModal(false);
  //   // dispatch(getExamQuestionList(filter)).then(() =>
  //   //   setIsOpenCreateExamModal(false)
  //   // );
  // };

  // old code
  // useEffect(() => {
  //   if (selectedAll) {
  //     dispatch(getAllDataExamQuestion(initFilter));
  //   } else {
  //     setSelectedId([]);
  //   }
  // }, [selectedAll]);
  // useEffect(() => {}, [selectedId]);
  // useEffect(() => {
  //   if (selectedAll) {
  //     setSelectedId(allQuestions);
  //   }
  // }, [allQuestions]);

  // const handleCloseApproveExamQuestion = () => {
  //   setIsOpenApproveModal(false);
  //   handleSearch();
  // };
  // const handleDeleteExam = (value) => {
  //   const { id, status_adjust } = value;
  //   const { first_name, last_name } = JSON.parse(localStorage.getItem("user"));
  //   const request = {
  //     deleted_by: `${first_name} ${last_name}`,
  //   };

  //   dispatch(deleteExamCoe(id, request, status_adjust));
  // };
  // const handleSelectApproveExam = () => {
  //   let obj = {
  //     questions: selectedId.map((it) => ({ id: it.id, status: "active" })),
  //   };
  //   setExamQuestionObj(obj);
  //   setIsOpenApproveModal(true);
  // };

  return (
    <SidebarLayout title="คลังข้อสอบทดสอบความพร้อม">
      {/* {Boolean(isOpenCreateExamModal) && (
        <PopupManageExamQuestion open onClose={() => handleCloseCreateExam()} />
      )} */}
      {/* {Boolean(isOpenCreateExamModal) && (
        <PopupCreateExamCoE open onClose={() => handleCloseCreateExam()} />
      )} */}
      {/* {Boolean(isOpenApproveModal) && (
        <PopupApproveExamQuestion
          open
          onClose={() => handleCloseApproveExamQuestion()}
          obj={examQuestionObj}
        />
      )} */}
      <Div>
        <SubMenu
          menu={[
            { path: "/library-train-coe/examination/all", text: "ข้อสอบ" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="รหัสข้อสอบ"
                  value={filter.code}
                  onChange={(e) => handleFilterChange("code", e.target.value)}
                  name="search_code"
                />
                <TextField
                  label="โจทย์"
                  value={filter.question}
                  onChange={(e) =>
                    handleFilterChange("question", e.target.value)
                  }
                  name="search_question"
                />
                <TextField
                  label="ผู้ออกข้อสอบ"
                  value={filter.examOwner}
                  onChange={(e) =>
                    handleFilterChange("examOwner", e.target.value)
                  }
                  name="search_examOwner"
                />
                <Select
                  label="หมวดวิชา"
                  value={filter.groupSubject}
                  items={initialGroupSubjects}
                  defaultValue="หมวดวิชา"
                  className="field-subjectId"
                  onChange={(e) =>
                    handleFilterChange("groupSubject", e.target.value)
                  }
                />
                <Select
                  label="วิชา"
                  value={filter.subject}
                  items={subjects}
                  defaultValue="วิชา"
                  className="field-subjectId"
                  onChange={(e) =>
                    handleFilterChange("subject", e.target.value)
                  }
                />

                <TextField
                  label="หัวข้อ"
                  value={filter.topicName}
                  onChange={(e) =>
                    handleFilterChange("topicName", e.target.value)
                  }
                  name="topicName"
                />
              </div>
              <div className="group-field">
                <Select
                  label="ระดับความยาก"
                  value={filter.level}
                  items={difficultyLevelList}
                  defaultValue="ระดับความยาก"
                  className="field-examinerType"
                  onChange={(e) => handleFilterChange("level", e.target.value)}
                />
                <Select
                  label="สถานะ"
                  name={"status"}
                  value={filter.status}
                  items={statusList}
                  defaultValue="สถานะ"
                  className="field-examinerType"
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  {/* <Button>อัพข้อสอบเป็นชุด</Button>
                  <Button onClick={() => handleSelectApproveExam()}>
                    อนุมัติข้อสอบ
                  </Button> */}
                  {/* <Button
                    color={"secondary"}
                    onClick={() => handleCreateExam()}
                  >
                    สร้างข้อสอบ
                  </Button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="table-wrapper">
            <div className="mb-1-rem">จำนวนข้อสอบ {total} ข้อ</div>
            <Table
              columns={columns}
              rows={examQuestionList}
              rowCount={total}
              pageSize={limit}
              page={skip / limit}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              // onDeleteRow={handleDeleteExam}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ExaminationTrainCoEAll;
