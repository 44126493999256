import React, { useEffect } from "react";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import styled from "styled-components";

import { useDispatch } from "react-redux";
import { uploadFileQuestionExam } from "redux/actions/library-coe";

const QuillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .error-message {
    color: red;
  }
`;

const QuillContainer = styled.div`
  width: 1200px;
  border: 1px solid ${(props) => (props.error ? "red" : "#e8ecefƒ")};
`;

function NewTextEditor({ setValue, value, error, ...props }) {
  // external hook
  const dispatch = useDispatch();

  // initiate quill
  const theme = "snow";
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "formula"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ script: "super" }, { script: "sub" }],
      ["clean"],
    ],
  };
  const { quill, quillRef } = useQuill({
    theme,
    modules,
  });

  const checkStringFormat = (str) => {
    try {
      const json = JSON.parse(str);

      if (Array.isArray(json) && json.length > 0 && "insert" in json[0]) {
        return "json";
      }
    } catch (error) {}

    if (/https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g.test(str)) {
      return "html-img";
    }
    if (/<[a-z][\s\S]*>/i.test(str)) {
      return "html";
    }

    return "string";
  };
  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };
  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("file", file);

    const imageUrl = await dispatch(uploadFileQuestionExam(body));
    insertToEditor(imageUrl);
  };
  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  useEffect(() => {
    if (quill) {
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      quill.on("text-change", (delta, oldDelta, source) => {
        const deltaContent = quill.getContents();
        setValue(deltaContent.ops);
      });
    }
  }, [quill]);

  // initiate quill value
  useEffect(() => {
    if (quill && value) {
      quill.setContents([]);

      try {
        const delta = JSON.parse(value);
        if (delta && Array.isArray(delta)) {
          quill.setContents(delta);
        }
      } catch (e) {
        const formatString = checkStringFormat(value);

        if (formatString === "html-img") {
          const regex =
            /https?:\/\/[^/\s]+\/\S+\.(png|jpg|JPG|gif|GIF|bmp|svg)/g;
          const result = value.replace(regex, '<img src="$&" />');
          const delta = quill.clipboard.convert(result);
          quill.setContents(delta);
        } else {
          const delta = quill.clipboard.convert(value);
          quill.setContents(delta);
        }
      }
    }
  }, [quill, value]);

  return (
    <QuillWrapper>
      <QuillContainer error={error} {...props}>
        <div
          ref={quillRef}
          style={{ maxWidth: "100%", height: "130px" }}
          {...props}
        />
      </QuillContainer>
      {error && <p className="error-message">กรุณากรอก</p>}
    </QuillWrapper>
  );
}

export default NewTextEditor;
