const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const CLEAR_ALERT = "CLEAR_ALERT";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const LOGIN = genRequest("LOGIN");
export const VERIFY_TOKEN = genRequest("VERIFY_TOKEN");

export const GET_MASTER_DATA_PROVINCE = "GET_MASTER_DATA_PROVINCE";
export const GET_MASTER_DATA_DISTRICT = "GET_MASTER_DATA_DISTRICT";
export const GET_MASTER_DATA_SUB_DISTRICT = "GET_MASTER_DATA_SUB_DISTRICT";
export const GET_MASTER_DATA_BANK = "GET_MASTER_DATA_BANK";
export const GET_MASTER_DATA_NATIONALITY = "GET_MASTER_DATA_NATIONALITY";
export const GET_MASTER_DATA_TAG = "GET_MASTER_DATA_TAG";
export const GET_MASTER_DATA_SUBJECT = "GET_MASTER_DATA_SUBJECT";
export const GET_MASTER_DATA_JOB = "GET_MASTER_DATA_JOB";
export const GET_MASTER_DATA_COE_REPORT = "GET_MASTER_DATA_COE_REPORT";
export const GET_MASTER_DATA_COE_SUB_REPORT = "GET_MASTER_DATA_COE_SUB_REPORT";

export const ADD_MEMBER = genRequest("ADD_MEMBER");
export const EDIT_MEMBER_INFO = genRequest("EDIT_MEMBER_INFO");
export const EDIT_PERSONAL_INFO = genRequest("EDIT_PERSONAL_INFO");
export const EDIT_MEMBER_STATUS = genRequest("EDIT_MEMBER_STATUS");
export const UPLOAD_IMAGE = genRequest("UPLOAD_IMAGE");
export const GET_MEMBER_LIST = genRequest("GET_MEMBER_LIST");
export const GET_MEMBER_BY_ID = genRequest("GET_MEMBER_BY_ID");
export const RESEND_VERIFY_EMAIL = genRequest("RESEND_VERIFY_EMAIL");
export const FORGET_PASSWORD = genRequest("FORGET_PASSWORD");
export const APPROVE_DOCUMENT_MEMBER = "APPROVE_DOCUMENT_MEMBER";
export const REQUEST_DOCUMENT_MEMBER = "REQUEST_DOCUMENT_MEMBER";

export const ADD_SEGMENT = genRequest("ADD_SEGMENT");
export const ADD_USER_SEGMENT = genRequest("ADD_USER_SEGMENT");
export const PATCH_SEGMENT = genRequest("PATCH_SEGMENT");
export const DELETE_SEGMENT_ITEM = genRequest("DELETE_SEGMENT_ITEM");
export const DELETE_USER_SEGMENT_ITEM = genRequest("DELETE_USER_SEGMENT_ITEM");
export const GET_SEGMENT_LIST = genRequest("GET_SEGMENT_LIST");
export const GET_SEGMENT_BY_ID = genRequest("GET_SEGMENT_BY_ID");

export const GET_PRODUCT_LIST = genRequest("GET_PRODUCT_LIST");
export const ADD_QUOTATION = genRequest("ADD_QUOTATION");
export const CREATE_INVOICE = genRequest("CREATE_INVOICE");
export const CREATE_RECEIPT = genRequest("CREATE_RECEIPT");
export const CREATE_DELIVERY_NOTE = genRequest("CREATE_DELIVERY_NOTE");
export const GET_INVOICE_BY_ID = genRequest("GET_INVOICE_BY_ID");
export const GET_QUOTATION_BY_ID = genRequest("GET_QUOTATION_BY_ID");
export const GET_RECEIPT_BY_ID = genRequest("GET_RECEIPT_BY_ID");
export const GET_DELIVERY_BY_ID = genRequest("GET_DELIVERY_BY_ID");
export const GET_QUOTATION_LIST = genRequest("GET_QUOTATION_LIST");
export const GET_INVOICE_LIST = genRequest("GET_INVOICE_LIST");
export const PATCH_INVOICE_STATUS = genRequest("PATCH_INVOICE_STATUS");

export const CREATE_EXAM_CAMPAIGN = genRequest("CREATE_EXAM_CAMPAIGN");
export const GET_EXAM_CAMPAIGN_LIST = genRequest("GET_EXAM_CAMPAIGN_LIST");
export const GET_EXAM_CAMPAIGN_BY_DATE = genRequest(
  "GET_EXAM_CAMPAIGN_BY_DATE"
);
export const PATCH_EXAM_STATUS = genRequest("PATCH_EXAM_STATUS");
export const CREATE_REPORT_CONFIG = genRequest("CREATE_REPORT_CONFIG");
export const GET_DISC_RESULT_LIST = genRequest("GET_DISC_RESULT_LIST");
export const GET_REPORT_CONFIG_LIST = genRequest("GET_REPORT_CONFIG_LIST");
export const GET_REPORT_CONFIG_BY_ID = genRequest("GET_REPORT_CONFIG_BY_ID");
export const PATCH_REPORT_CONFIG = genRequest("PATCH_REPORT_CONFIG");
export const GET_CAMPAIGN_LIST = genRequest("GET_CAMPAIGN_LIST");
export const GET_REPORT_CONFIG_LIST_DROPDOWN = genRequest(
  "GET_REPORT_CONFIG_LIST_DROPDOWN"
);
export const PATCH_EXAM_CAMPAIGN_STATUS = genRequest(
  "PATCH_EXAM_CAMPAIGN_STATUS"
);

export const GET_EXAM_USER_LIST = genRequest("GET_EXAM_USER_LIST");
export const GET_EXAM_USER_DETAIL = genRequest("GET_EXAM_USER_DETAIL");
export const EDIT_EXAM_USER_DETAIL = genRequest("EDIT_EXAM_USER_DETAIL");
export const GET_EXAM_USER_DETAIL_SUBJECT_LIST = genRequest(
  "GET_EXAM_USER_DETAIL_SUBJECT_LIST"
);

export const GET_EXAM_QUESTION_LIST = genRequest("GET_EXAM_QUESTION_LIST");
export const GET_EXAM_QUESTION_BY_ID = genRequest("GET_EXAM_QUESTION_BY_ID");
export const GET_EXAM_QUESTION_CHOICE_BY_ID = genRequest(
  "GET_EXAM_QUESTION_CHOICE_BY_ID"
);
export const CREATE_EXAM_QUESTION = genRequest("CREATE_EXAM_QUESTION");
export const PATCH_EXAM_QUESTION_CHOICE_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_CHOICE_BY_ID"
);
export const PATCH_EXAM_QUESTION_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_BY_ID"
);
export const PATCH_EXAM_QUESTION_STATUS_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_STATUS_BY_ID"
);

export const APPROVE_EXAM_QUESTION = genRequest("APPROVE_EXAM_QUESTION");
export const GET_EXAM_SET_LIST = genRequest("GET_EXAM_SET_LIST");
export const GET_RANDOM_EXAM = genRequest("GET_RANDOM_EXAM");
export const GET_ALL_QUESTION_ACTIVE = genRequest("GET_ALL_QUESTION_ACTIVE");
export const GET_ALL_DATA_QUESTION_ACTIVE = genRequest(
  "GET_ALL_DATA_QUESTION_ACTIVE"
);
export const GET_ALL_DATA_EXAM_QUESTION_LIST = genRequest(
  "GET_ALL_DATA_EXAM_QUESTION_LIST"
);
export const GET_CLIENT_LIBRARY_LIST = genRequest("GET_CLIENT_LIBRARY_LIST");
export const CREATE_EXAM_SET = genRequest("CREATE_EXAM_SET");
export const GET_EXAM_SET_BY_ID = genRequest("GET_EXAM_SET_BY_ID");
export const PATCH_EXAM_SET = genRequest("PATCH_EXAM_SET");
export const CLEAR_EXAM_SET = "CLEAR_EXAM_SET";
export const GET_NOTIFICATIONS = genRequest("GET_NOTIFICATIONS");
export const GET_CONDITIONS = genRequest("GET_CONDITIONS");
export const CREATE_NOTIFICATION = genRequest("CREATE_NOTIFICATION");
export const STATUS_NOTIFICATION = genRequest("STATUS_NOTIFICATION");
export const GET_USERS_NOTIFICATION = genRequest("GET_USERS_NOTIFICATION");
export const SET_USER_NOTIFICATION_SELECTED = "SET_USER_NOTIFICATION_SELECTED";
export const GET_NOTIFICATION_BY_ID = genRequest("GET_NOTIFICATION_BY_ID");
export const EDIT_NOTIFICATION = genRequest("EDIT_NOTIFICATION");
export const UPDATE_EXTRA_CONDITION = genRequest("UPDATE_EXTRA_CONDITION");
export const GET_USERS_EXTRA_CONDITION = genRequest(
  "GET_USERS_EXTRA_CONDITION"
);
export const GET_MEMBER_TYPES = genRequest("GET_MEMBER_TYPES");
export const GET_CORPORATE_TYPES = genRequest("GET_CORPORATE_TYPES");
export const DELETE_NOTIFICATION = genRequest("DELETE_NOTIFICATION");
export const SEND_IMMEDIATELY_NOTIFICATION = genRequest(
  "SEND_IMMEDIATELY_NOTIFICATION"
);

export const GET_REPORT_CSV = "GET_REPORT_CSV";

export const GET_REPORT_EXAM = genRequest("GET_REPORT_EXAM");
export const GET_REPORT_1 = genRequest("GET_REPORT_1");
export const GET_REPORT_EXTRA_1 = genRequest("GET_REPORT_EXTRA_1");
export const GET_REPORT_2 = genRequest("GET_REPORT_2");
export const GET_REPORT_EXTRA_2 = genRequest("GET_REPORT_EXTRA_2");
export const GET_REPORT_3 = genRequest("GET_REPORT_3");
export const GET_REPORT_EXTRA_3 = genRequest("GET_REPORT_EXTRA_3");
export const GET_EXPORT_REPORT_1 = genRequest("GET_EXPORT_REPORT_1");
export const GET_EXPORT_REPORT_EXTRA_1 = genRequest(
  "GET_EXPORT_REPORT_EXTRA_1"
);
export const GET_EXPORT_REPORT_2 = genRequest("GET_EXPORT_REPORT_2");
export const GET_EXPORT_REPORT_EXTRA_2 = genRequest(
  "GET_EXPORT_REPORT_EXTRA_2"
);
export const GET_EXPORT_REPORT_3 = genRequest("GET_EXPORT_REPORT_3");
export const GET_EXPORT_REPORT_EXTRA_3 = genRequest(
  "GET_EXPORT_REPORT_EXTRA_3"
);

export const GET_REPORT_EXAM_1 = genRequest("GET_REPORT_EXAM_1");
export const GET_EXPORT_REPORT_EXAM_1 = genRequest("GET_EXPORT_REPORT_EXAM_1");
export const GET_REPORT_EXAM_2 = genRequest("GET_REPORT_EXAM_2");
export const GET_EXPORT_REPORT_EXAM_2 = genRequest("GET_EXPORT_REPORT_EXAM_2");
export const GET_REPORT_EXAM_3 = genRequest("GET_REPORT_EXAM_3");
export const GET_EXPORT_REPORT_EXAM_3 = genRequest("GET_EXPORT_REPORT_EXAM_3");
export const GET_REPORT_EXAM_4 = genRequest("GET_REPORT_EXAM_4");
export const GET_EXPORT_REPORT_EXAM_4 = genRequest("GET_EXPORT_REPORT_EXAM_4");
export const GET_EXPORT_REPORT_EXAM = genRequest("GET_EXPORT_REPORT_EXAM");
export const GET_BATCH_UPDATE = genRequest("GET_BATCH_UPDATE");

// Call center
export const GET_TICKET_LIST = genRequest("GET_TICKET_LIST");
export const ADD_TICKET = genRequest("ADD_TICKET");
export const GET_MEMBER_INFO = genRequest("GET_MEMBER_INFO");
export const GET_MEMBER_INFO_BY_MEMNO = genRequest("GET_MEMBER_INFO_BY_MEMNO");
export const GET_TICKET_BY_ID = genRequest("GET_TICKET_BY_ID");
export const ADD_TICKET_STATUS = genRequest("ADD_TICKET_STATUS");
export const GET_TICKET_TYPE_LIST = genRequest("GET_TICKET_TYPE_LIST");
export const EDIT_TICKET = genRequest("EDIT_TICKET");
export const GET_TICKET_HISTORY_LIST = genRequest("GET_TICKET_HISTORY_LIST");
export const GET_CORPORATE_INFO = genRequest("GET_CORPORATE_INFO");
export const GET_CORPORATE_INFO_BY_ID = genRequest("GET_CORPORATE_INFO_BY_ID");
export const DOWNLOAD_MEMBER_EXPIRED_CSV = genRequest(
  "DOWNLOAD_MEMBER_EXPIRED_CSV"
);
export const DOWNLOAD_CORPORATE_EXPIRED_CSV = genRequest(
  "DOWNLOAD_CORPORATE_EXPIRED_CSV"
);
// exam-coe
export const GET_EXAM_COE_LIST = genRequest("GET_EXAM_COE_LIST");
export const GET_EXAM_COE_BY_ID = genRequest("GET_EXAM_COE_BY_ID");
export const EDIT_EXAM_COE = genRequest("EDIT_EXAM_COE");
export const DELETE_EXAM_COE = genRequest("DELETE_EXAM_COE");
export const GET_SUBJECT_LIST = genRequest("GET_SUBJECT_LIST");
export const GET_EXAM_SUBJECTS = genRequest("GET_EXAM_SUBJECTS");
export const GET_EXAM_TOPICS = genRequest("GET_EXAM_TOPICS");
export const CREATE_EXAM_COE = genRequest("CREATE_EXAM_COE");
export const UPLOAD_FILE_QUESTION_EXAM = genRequest(
  "UPLOAD_FILE_QUESTION_EXAM"
);
export const SAVE_FILTER_EXAM = "SAVE_FILTER_EXAM";
export const SAVE_PAGE = "SAVE_PAGE";
export const SAVE_TAB = "SAVE_TAB";
export const GET_EXAM_TRAIN_COE = genRequest("GET_EXAM_TRAIN_COE");
export const GET_EXAM_TRAIN_COE_BY_ID = genRequest("GET_EXAM_TRAIN_COE_BY_ID");
export const EDIT_EXAM_TRAIN_COE = genRequest("EDIT_EXAM_TRAIN_COE");
export const DELETE_EXAM_TRAIN_COE = genRequest("DELETE_EXAM_TRAIN_COE");
