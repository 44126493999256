import {
  GET_EXAM_COE_LIST,
  GET_EXAM_COE_BY_ID,
  EDIT_EXAM_COE,
  DELETE_EXAM_COE,
  GET_SUBJECT_LIST,
  GET_EXAM_SUBJECTS,
  GET_EXAM_TOPICS,
  SAVE_FILTER_EXAM,
  SAVE_PAGE,
  SAVE_TAB,
  CREATE_EXAM_COE,
  UPLOAD_FILE_QUESTION_EXAM,
  GET_EXAM_TRAIN_COE,
  GET_EXAM_TRAIN_COE_BY_ID,
  EDIT_EXAM_TRAIN_COE,
  DELETE_EXAM_TRAIN_COE,
} from "redux/actionTypes";

const initialState = {
  examCoeList: [],
  examCoeDetail: {},
  subjectList: [],
  examSubjectList: [],
  examTopicList: [],
  examTrainCoeList: [],
  examTrainCoeDetail: {},
  keepedFilterExam: {
    code: "",
    question: "",
    examOwner: "",
    groupSubject: "",
    subject: "",
    topicName: "",
    level: "",
    status: "",
    statusAdjust: "",
  },
  keepedPage: 0,
  keepedTab: "not-adjust",
  total: 0,
  limit: 10,
  skip: 0,
  state: "",
  isLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EXAM_COE_LIST.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_COE_LIST.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_COE_LIST.SUCCESS: {
      const { data, total, limit, skip } = action.data;

      return {
        ...state,
        examCoeList: data,
        total,
        limit,
        skip,
        state: "GET_EXAM_COE_LIST.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_COE_LIST.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_COE_LIST.FAILURE",
        isLoading: false,
      };
    }
    case GET_SUBJECT_LIST.REQUEST: {
      return {
        ...state,
        state: "GET_SUBJECT_LIST.REQUEST",
        isLoading: true,
      };
    }
    case GET_SUBJECT_LIST.SUCCESS: {
      const { data } = action;

      return {
        ...state,
        subjectList: data,
        state: "GET_SUBJECT_LIST.SUCCESS",
        isLoading: false,
      };
    }
    case GET_SUBJECT_LIST.FAILURE: {
      return {
        ...state,
        state: "GET_SUBJECT_LIST.FAILURE",
        isLoading: false,
      };
    }
    case GET_EXAM_COE_BY_ID.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_COE_BY_ID.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_COE_BY_ID.SUCCESS: {
      const { data } = action;

      return {
        ...state,
        examCoeDetail: data,
        state: "GET_EXAM_COE_BY_ID.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_COE_BY_ID.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_COE_BY_ID.FAILURE",
        isLoading: false,
      };
    }
    case EDIT_EXAM_COE.REQUEST: {
      return {
        ...state,
        state: "EDIT_EXAM_COE.REQUEST",
        isLoading: true,
      };
    }
    case EDIT_EXAM_COE.SUCCESS: {
      return {
        ...state,
        state: "EDIT_EXAM_COE.SUCCESS",
        isLoading: false,
      };
    }
    case EDIT_EXAM_COE.FAILURE: {
      return {
        ...state,
        state: "EDIT_EXAM_COE.FAILURE",
        isLoading: false,
      };
    }
    case DELETE_EXAM_COE.REQUEST: {
      return {
        ...state,
        state: "DELETE_EXAM_COE.REQUEST",
        isLoading: true,
      };
    }
    case DELETE_EXAM_COE.SUCCESS: {
      return {
        ...state,
        state: "DELETE_EXAM_COE.SUCCESS",
        isLoading: false,
      };
    }
    case DELETE_EXAM_COE.FAILURE: {
      return {
        ...state,
        state: "DELETE_EXAM_COE.FAILURE",
        isLoading: false,
      };
    }
    case GET_EXAM_SUBJECTS.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_SUBJECTS.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_SUBJECTS.SUCCESS: {
      const { data, total, limit, skip } = action.data;

      return {
        ...state,
        examSubjectList: data,
        total,
        limit,
        skip,
        state: "GET_EXAM_SUBJECTS.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_SUBJECTS.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_SUBJECTS.FAILURE",
        isLoading: false,
      };
    }
    case GET_EXAM_TOPICS.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_TOPICS.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_TOPICS.SUCCESS: {
      const { data, total, limit, skip } = action.data;

      return {
        ...state,
        examTopicList: data,
        total,
        limit,
        skip,
        state: "GET_EXAM_TOPICS.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_TOPICS.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_TOPICS.FAILURE",
        isLoading: false,
      };
    }
    case SAVE_FILTER_EXAM: {
      const { data } = action;
      return {
        ...state,
        keepedFilterExam: data,
      };
    }
    case SAVE_PAGE: {
      const { data } = action;
      return {
        ...state,
        keepedPage: data,
      };
    }
    case SAVE_TAB: {
      const { data } = action;
      return {
        ...state,
        keepedTab: data,
      };
    }
    case CREATE_EXAM_COE.REQUEST: {
      return {
        ...state,
        state: "CREATE_EXAM_COE.REQUEST",
        isLoading: true,
      };
    }
    case CREATE_EXAM_COE.SUCCESS: {
      return {
        ...state,
        state: "CREATE_EXAM_COE.SUCCESS",
        isLoading: false,
      };
    }
    case CREATE_EXAM_COE.FAILURE: {
      return {
        ...state,
        state: "CREATE_EXAM_COE.FAILURE",
        isLoading: false,
      };
    }
    case UPLOAD_FILE_QUESTION_EXAM.REQUEST: {
      return {
        ...state,
        state: "UPLOAD_FILE.REQUEST",
        isLoading: true,
      };
    }
    case UPLOAD_FILE_QUESTION_EXAM.SUCCESS: {
      return {
        ...state,
        state: "UPLOAD_FILE.SUCCESS",
        isLoading: false,
      };
    }
    case UPLOAD_FILE_QUESTION_EXAM.FAILURE: {
      return {
        ...state,
        state: "UPLOAD_FILE.FAILURE",
        isLoading: false,
      };
    }
    case GET_EXAM_TRAIN_COE.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_TRAIN_COE.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_TRAIN_COE.SUCCESS: {
      const { data, total, limit, skip } = action.data;

      return {
        ...state,
        examTrainCoeList: data,
        total,
        limit,
        skip,
        state: "GET_EXAM_TRAIN_COE.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_TRAIN_COE.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_TRAIN_COE.FAILURE",
        isLoading: false,
      };
    }
    case GET_EXAM_TRAIN_COE_BY_ID.REQUEST: {
      return {
        ...state,
        state: "GET_EXAM_TRAIN_COE_BY_ID.REQUEST",
        isLoading: true,
      };
    }
    case GET_EXAM_TRAIN_COE_BY_ID.SUCCESS: {
      const { data } = action;

      return {
        ...state,
        examTrainCoeDetail: data,
        state: "GET_EXAM_TRAIN_COE_BY_ID.SUCCESS",
        isLoading: false,
      };
    }
    case GET_EXAM_TRAIN_COE_BY_ID.FAILURE: {
      return {
        ...state,
        state: "GET_EXAM_TRAIN_COE_BY_ID.FAILURE",
        isLoading: false,
      };
    }
    case EDIT_EXAM_TRAIN_COE.REQUEST: {
      return {
        ...state,
        state: "EDIT_EXAM_TRAIN_COE.REQUEST",
        isLoading: true,
      };
    }
    case EDIT_EXAM_TRAIN_COE.SUCCESS: {
      return {
        ...state,
        state: "EDIT_EXAM_TRAIN_COE.SUCCESS",
        isLoading: false,
      };
    }
    case EDIT_EXAM_TRAIN_COE.FAILURE: {
      return {
        ...state,
        state: "EDIT_EXAM_TRAIN_COE.FAILURE",
        isLoading: false,
      };
    }
    case DELETE_EXAM_TRAIN_COE.REQUEST: {
      return {
        ...state,
        state: "DELETE_EXAM_TRAIN_COE.REQUEST",
        isLoading: true,
      };
    }
    case DELETE_EXAM_TRAIN_COE.SUCCESS: {
      return {
        ...state,
        state: "DELETE_EXAM_TRAIN_COE.SUCCESS",
        isLoading: false,
      };
    }
    case DELETE_EXAM_TRAIN_COE.FAILURE: {
      return {
        ...state,
        state: "DELETE_EXAM_TRAIN_COE.FAILURE",
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
