import React from "react";

import styled from "styled-components";
import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import { Stack } from "@mui/material";

const Div = styled.div`
  text-align: center;

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }

  h2,
  p {
    margin: 0;
  }
`;

function PopupAmountExamForTopicSubject({ open, onClose, content }) {
  return (
    <BaseModal open={open} onClose={onClose}>
      <Div>
        <h2 className="mb-20-px">วิชา {content?.subject_name}</h2>
        <p className="mb-16-px">มีจำนวนข้อสอบ {content?.amount_exam} ข้อ</p>
        <Stack direction="row" justifyContent="center">
          <Button onClick={onClose}>ปิด</Button>
        </Stack>
      </Div>
    </BaseModal>
  );
}

export default PopupAmountExamForTopicSubject;
